import React, {Component ,useState} from 'react'
import { connect } from 'react-redux'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import axios from 'axios'

import {Divider ,TextField,Grid,Button,Box} from '@mui/material';

import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useParams, useLocation ,useNavigate  } from 'react-router-dom'


import {MenuItem,Chip,Alert,Switch,IconButton} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import { makeStyles } from "@mui/styles"
import mainStyle from "../../assets/mainStyle";
import Page404 from '../Page404';
import Loader from '../../layout/Loader';
import Login from '../auth/Login';

const useStyles = makeStyles({
  ...mainStyle,
  DeviceOptions:{
      marginBottom:"20px"
  },
  inputItem:{
    display:"flex",
    marginBottom: "15px !important;",
    ['@media (max-width:900px)']: { 
      display:"block",
      marginBottom: "30px !important;",
    }
  },
  inputItemFieldName:{
    flexGrow:"1",
    marginTop:" 16px !important",
    marginBottom: "8px !important;",
    marginRight: "8px !important",
    ['@media (max-width:900px)']: { 
      width:"100%",
      marginRight: "",
    }
  },
  inputItemFieldUnit:{
    flexGrow:"1",
    marginTop:" 16px !important",
    marginBottom: "8px !important;",
    width:"300px",
    ['@media (max-width:900px)']: { 
      width:"100%",
    }
  },
  InputRemoveButton:{
    width:"50px",
    height:"50px"
  },
  inputGroup:{
    display:"flex",
    flexGrow:"1",
    ['@media (max-width:900px)']: { 
      width:"100%",
    }
  }
})



const AddDevice = ({user,Units,Loaded,Empty}) => {

    const classes = useStyles();

    const navigate = useNavigate()
    const [values, setValues] = useState({});
    const [inputs, setInputs] = useState([]);
    const [{loading, error,saved}, setStatus] = useState({});
    const [tagValues, setTagValues] = useState("");
        
    useFirestoreConnect([
      { collection: 'Units', storeAs: 'Units',orderBy:"name"},
  ])




  const handleChangeInput = (index,event) => {
    setStatus({})
    var inputTemp = [...inputs]
    inputTemp[index][event.target.name] = event.target.value
    setInputs(inputTemp);
  };

  const handleAddInput = () => {
    setStatus({})
      setInputs([...inputs,{}]);
  };

  const handleRemoveInput = (index) => {
    setStatus({})

    var inputsTemp = [...inputs];
      
    inputsTemp.splice(index, 1);

    setInputs(inputsTemp);
  };
  







  const handleChange = (event) => {
    setValues({
        ...values,
        [event.target.name]: event.target.value
    });
};

    const handleCheckChange = (event) => {
      setStatus({})
      setValues({
        ...values,
        [event.target.name]: event.target.checked
      });
    };

    
  const handleTagChange = (event) => {
    setStatus({})
    setTagValues(event.target.value);
  };
  
  const handleTagPress = (event) =>{
    if (event.keyCode === 13)
    {
      event.preventDefault();
      handleTagAdd()
    }
  }

  
  const handleTagAdd = () =>
  {
    var tags = []

    if(values && values.tags)
      tags = [...values.tags]

    if(tagValues !== "")
    {
      tags.push(tagValues)
      setValues({
        ...values,
        tags:tags
      })
    }
    else
    {
      alert("Please add tag")
    }
    
    setTagValues("")
  }
  
  const handleTagDelete = (index) =>{
    
    var tags = null
    
    if(values && values.tags)
      tags = [...values.tags]

    if(!tags)
      return
      

    tags.splice(index, 1);
    
    setValues({
      ...values,
      tags:tags
    })
  }

  
  
    
    const handleSubmit = (event) => { 
        event.preventDefault();
        
        var valuesTemp = {...values}
        valuesTemp.inputs = {}
        inputs.forEach((element) => {
          if(element.id)
            valuesTemp.inputs[element.id]   = element
          else if(element.name)
            valuesTemp.inputs[element.name] = element
        });


        if(!valuesTemp.name || values.name == "" || !valuesTemp.description || valuesTemp.description == "" || !valuesTemp.inputs || inputs.length == 0 )
        {
          setStatus({error:"Name, description and inputs are required"})
          return
        }

        setStatus({loading:true})

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          axios.post('https://api.climatesens.com/add/new', valuesTemp, { "headers":{"Authorization": idToken}})
          .then(function (response) {


            if(!response.data.success || !response.data.DeviceId)
            {
              setStatus({error:"Something went wrong"})
              return
            }
            navigate("/add/"+response.data.DeviceId)
          })
          .catch(function (error) {
              console.log("error");
              console.log(error);
              setStatus({error:"Server problem try again later"})
          })
          .finally(function () {

          }); 
        }).catch(function(error) {
         // if(timeoutTimer)
          //    clearInterval(timeoutTimer);
              setStatus({error:"User validation problem"})
        });
    }




    if(!user)
        return(<Login/>)

    if(!Loaded)
    return(<Loader/>)


    if(!user)
        return(<Page404/>)


        return (
            <main className={classes.root}>
                <Box>
                        <h1>Configure Device</h1>

                        <Box sx={{height:"50px"}}>
                            {error ? <Alert severity="error">Error — {error==true ? "There was an error." : error}</Alert> :null}
                            {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                            {saved ? <Alert severity="success">Saved</Alert> :null}
                        </Box>


                        {/*Details.type ? <Box>Device: <NavLink to={"/device/"+Details.type}>{Details.type}</NavLink></Box> : null*/}
                        
                        <TextField margin="normal" fullWidth value={values.name ? values.name : null} onChange={handleChange} name="name" className={classes.TextField} variant="outlined" label="Name" />
                        <TextField margin="normal" fullWidth value={"url" in values ? values.url  : null}  onChange={handleChange} name="url" className={classes.TextField} variant="outlined" label="URL" />
                        <TextField margin="normal" fullWidth value={"urlBuy" in values ? values.urlBuy : null}  onChange={handleChange} name="urlBuy" className={classes.TextField} variant="outlined" label="Buy URL" />
                        <TextField margin="normal" fullWidth value={"urlMake" in values ? values.urlMake : null}  onChange={handleChange} name="urlMake" className={classes.TextField} variant="outlined" label="Instruction URL" />
                        <TextField margin="normal" fullWidth value={"image" in values ? values.image : null}  onChange={handleChange} name="image" className={classes.TextField} variant="outlined" label="Image URL" />
                        
                        
                        <Box sx={{display:"flex", marginBottom:"10px", marginTop:"20px"}}>
                        <p>Tags:</p>
                      <TextField  sx={{margin:" 0 10px  "}} fullWidth name="addTag" id="addTagIdInput" value={tagValues} onChange={handleTagChange} onKeyUp={handleTagPress} variant="outlined" />
                      <Button  variant="contained" onClick={handleTagAdd}>Add</Button>

                        </Box>
                        <Box sx={{display:"flex",marginBottom:"20px",}}>
                        {("tags" in values?values.tags:[]).map((item,index)=>{
                          return(<Chip label={item}  sx={{marginRight:"10px",}} variant="outlined" onDelete={()=>handleTagDelete(index)} />)
                        })}
                        </Box>



                        <Box sx={{marginBottom:"50px"}}>
                          <h3>Inputs</h3>
                          <Box>
                            {(inputs).map((item,index)=>{
                              return(<Box className={classes.inputItem}>
                                <TextField margin="normal" fullWidth value={item.name ? item.name : null} onChange={(event)=>handleChangeInput(index,event)} name="name"  className={classes.inputItemFieldName} variant="outlined" label="Input Name" />
                              <Box className={classes.inputGroup}>
                              <TextField  select label="Unit"  className={classes.inputItemFieldUnit} name="unit"  defaultValue="Unknown" value={item.unit ? item.unit : "Unknown"} onChange={(event)=>handleChangeInput(index,event)}  >
                                  {Units && Units.map((option) => {
                                    if( !option.id || !option.name)
                                    return null

                                  if(option.id == "Unknown" )
                                    return(<MenuItem  disabled value={option.id}>
                                      {option.name} {option.unitSymbol ? "("+option.unitSymbol+")" :null}
                                    </MenuItem>)

                                    return(<MenuItem value={option.id}>
                                             {option.name} {option.unitSymbol ? "("+option.unitSymbol+")" :null}
                                           </MenuItem>)
                                  })}
                                </TextField>

                                  <Box sx={{padding:"20px"}}>
                                    <IconButton className={classes.InputRemoveButton} color="primary" aria-label="Remove input" onClick={()=>handleRemoveInput(index)} >
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                  </Box>
                                
                              </Box>)
                            })}
                            <Button  variant="contained" onClick={handleAddInput}>Add inputs</Button>
                          </Box>
                        </Box>




                        <TextField margin="normal" fullWidth value={"description" in values ? values.description : null} onChange={handleChange} name="description" className={classes.TextField} variant="outlined" label="Description" multiline rows={4}/>
                        
                      <Box sx={{display:"flex"}}>
                        <Box sx={{margin:" 8px 20px 8px 0",}}>Private</Box>
                        <Switch
                          checked={"private" in values ? values.private : false  }
                          name="private"
                          onChange={handleCheckChange}
                        />
                      </Box>



                        {/*

                        {Details.inputs ? <Box>
                                <h2>Inputs</h2>
                                <ul>
                                    { inputs && inputs.map(input => {
                                        return (<li>{input}</li>)
                                    })}
                                </ul>  
                                </Box> : null*/}

                            <br/>

                            <Button margin="normal" onClick={handleSubmit}  type="submit" className={classes.SendButton} variant="contained">Save</Button>

                    </Box>
                
            </main>
        )
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
        //IdToken:firebase.auth().currentUser.getIdToken(),
        Units:state.firestore.ordered.Units,
        Loaded:isLoaded(state.firestore.ordered.Units),
        Empty:isEmpty(state.firestore.ordered.Units) 
    }
}

export default compose(
    connect(mapStateToProps)
)(AddDevice);