//import firebase from 'firebase';
import React,  {useState } from 'react'
 
import {useParams,   } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'
//import DeviceList from './devices/List'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
//import { firestore } from 'firebase'
import { NavLink } from 'react-router-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {Box,Button,TableContainer,Paper,Table,TableRow,TableBody,TableCell,TableHead} from '@mui/material';

import HorizontalAds from "../../layout/Ads/HorizontalAds"
import Loader from "../../layout/Loader"
import Page404 from "../Page404"

import parse from 'html-react-parser';


import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  },
  title:{
    color:"#444",
    textDecoration:"none",
  }
}));

const ActivityDevice = ({Details,Results,user,Loaded,Empty}) =>{    
        
    const classes = useStyles();

    let { id } = useParams();
    const [limit, setLimit] = useState(30);

    useFirestoreConnect([
        { collection: 'Devices', storeAs: 'ActivityDetails', doc:id},
        { collection: 'Devices', storeAs: 'ActivityResults', doc:id, subcollections: [ { collection: 'Results',limit:limit,orderBy:['timeStamp', 'desc']}]},

    ])


    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US');

    var inputs = []
    if(Details && Details.inputs)
        inputs = Object.keys(Details.inputs);




    if(!Loaded)
    {
        return (<Loader/>)
    }

    if(Empty)
    {
        return (<Page404/>)
    }

    var created = null
    var lasttimeStamp = null
    if(Details.created)
        created = new Date(Details.created.seconds*1000)
    if(Details.lasttimeStamp)
        lasttimeStamp = new Date(Details.lasttimeStamp.seconds*1000)



        return (
            <main className={classes.root}>
                    <Box>
                        <h1>Details - {Details.name}</h1>
                        {Details.admin && user && Details.admin.includes(user.uid) ?<Button sx={{marginBottom:"10px"}} component={NavLink} to={"/activity/"+id+"/edit"} variant="outlined">Edit</Button>:null}
                        {Details.created ? <Box><strong>Date created:</strong> {created.toUTCString()} ({timeAgo.format(Details.created.seconds*1000, 'time')})</Box> : null}
                        {Details.lasttimeStamp ? <Box><strong>Last message:</strong> {lasttimeStamp.toUTCString()} ({timeAgo.format(Details.lasttimeStamp.seconds*1000, 'time')})</Box> : null}
                        {Details.type ? <Box><strong>Device: </strong><NavLink to={"/device/"+Details.type}>{Details.type}</NavLink></Box> : null}

                        {Details.location ? <Box><strong>Location: </strong>{Details.location}</Box> : null}
                        {Details.private ? <Box><strong>Private:</strong> True</Box> : null}
                        
                        {Details.description ? <Box>{parse(Details.description)}</Box> : null}

                        {Details.inputs ? <Box>
                                <h2>Inputs</h2>
                                <ul>
                                    { inputs && inputs.map(input => {
                                        return (<li>{input}</li>)
                                    })}
                                </ul>  
                            </Box> : null}

                    </Box>
<HorizontalAds/>
                    <Box>
                        <h1>Results</h1>


                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    { inputs && inputs.map(input => {
                                        return (
                                            <TableCell align="right">{input}</TableCell>
                                        )
                                    })}
                                </TableRow>
                                </TableHead>
                                <TableBody>

                                    { Results && Results.map(Result => {
                                        
                                        
                                        var lasttimeStamp = "";
                                        var lasttimeStamp_timeAgo = "";
                                        if(Result.timeStamp)
                                        {
                                            var ts = new Date(Result.timeStamp.seconds*1000);
                                            lasttimeStamp = ts.toUTCString();

                                            lasttimeStamp_timeAgo = timeAgo.format(Result.timeStamp.seconds*1000, 'time');
                                        }
                                        

                                        return (
                                            <TableRow
                                        key={Result.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">{lasttimeStamp} ({lasttimeStamp_timeAgo})</TableCell>

                                        { inputs && inputs.map(input => {
                                                    if(Result.readings[input])
                                                        return (
                                                            <TableCell align="right">{Result.readings[input]}</TableCell>
                                                        )
                                                    else
                                                        return (
                                                            <TableCell></TableCell>
                                                        )
                                                })}
                                        </TableRow>

                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>

                        {!isLoaded(Results)? <Loader/> : null}
{isEmpty(Results)? <Box className={classes.NoActiveDevices}>No Active devices.</Box> : null}

                        <Box className={classes.LoadMoreBox}>
                <Button  className={classes.LoadMoreButton} variant="outlined" onClick={()=>setLimit(limit+50)}>Load More</Button>
            </Box>
                    </Box>
<HorizontalAds/>
            </main>
        )
}



const mapStateToProps = (state) => {

    var ActivityDetails = null;
    if(state.firestore.ordered.ActivityDetails)
    ActivityDetails = state.firestore.ordered.ActivityDetails[0];

    return {
        user:firebase.auth().currentUser,
        Details:ActivityDetails,
        Results:state.firestore.ordered.ActivityResults,
        Loaded:isLoaded(state.firestore.ordered.ActivityDetails),
        Empty:isEmpty(state.firestore.ordered.ActivityDetails) 
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props =>{
        return[
            //{ collection: 'Devices', storeAs: 'DeviceDetails', doc:props.match.params.id},
            //{ collection: 'Devices', storeAs: 'Results', doc:props.match.params.id, subcollections: [ { collection: 'Results',limit:30,orderBy:['timeStamp', 'desc']}]},
        ]
    })
    /*firestoreConnect([
        { collection: 'DeviceTypes', storeAs: 'deviceTypesCS', limit:4, where:[["category","==","CS"]]},
    ])*/
)(ActivityDevice);