import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'


import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import DraftsIcon from '@mui/icons-material/Drafts';
import AddIcon from '@mui/icons-material/Add';


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles(() => ({
  ... mainStyle,
  root:{
    width:"200px",
    //position: "sticky;",
    position: "fixed;",
    top: 50,
    bottom: 0,
    left: 0,
    zIndex: 100,
    padding: "0",
    boxShadow: "inset -1px 0 0 rgb(0 0 0 / 10%)",
    backgroundColor: "#f8f9fa;",
    ['@media (max-width:750px)']: { 
        display:"none"
    }
  }
}));





const Sidebar = ({user, sidebar}) => {

    const classes =  useStyles();
    



    return (<Box className={classes.root }>
        
        <List>
        {user ? <>
        
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/">
                    <ListItemText primary="My Dashboard" />
                </ListItemButton>
            </ListItem>
            {/*<ListItem disablePadding>
                <ListItemButton component={NavLink} to="/account/devices">
                    <ListItemText primary="My Devices" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/account/activity">
                    <ListItemText primary="My Activity" />
                </ListItemButton>
            </ListItem>
            {/*<ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/account/reports">
                    <ListItemText primary="My Reports" />
                </ListItemButton>
            </ListItem>*/}
            <ListItem disablePadding>
                <ListItemButton  component={NavLink} to="/add">
                    <ListItemText primary="Add Device" />
                </ListItemButton>
            </ListItem>

          <Divider />
        </> : null

        }



            <ListItem disablePadding>
                <ListItemButton component={NavLink} to={user?"/home":"/"}>
                    <ListItemText primary="Home" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/devices">
                    <ListItemText primary="Devices" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/activity">
                    <ListItemText primary="Activity" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/products">
                    <ListItemText primary="Products" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/reports">
                    <ListItemText primary="Reports" />
                </ListItemButton>
            </ListItem>
            
      <Divider />


      <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/about">
                    <ListItemText primary="About" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/contact">
                    <ListItemText primary="Contact" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/documents">
                    <ListItemText primary="Documents" />
                </ListItemButton>
            </ListItem>
            

            <ListItem disablePadding>
                <ListItemButton component="a" target="_blank" href="https://feedback.spried.com/service/climate-sens">
                    <ListItemText primary="Feedback/bugs" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton  component={NavLink} to="/sponsors">
                    <ListItemText primary="Sponsors" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton component={NavLink} to="/terms">
                    <ListItemText primary="Terms and Conditions" />
                </ListItemButton>
            </ListItem>

        </List>


        {user == null ?
                <Box disablePadding className={classes.center}>
                    <Button variant="outlined" component={NavLink} to="/login" >Login</Button>
                </Box>
             : null}

  </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Sidebar)