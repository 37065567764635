import React, {useState} from 'react'
//import firebase from 'firebase';
import { NavLink } from 'react-router-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import {Box,Button,Grid} from '@mui/material';

import HorizontalAds from "../../layout/Ads/HorizontalAds"
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import { useFirestoreConnect } from 'react-redux-firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';import { connect } from 'react-redux'
import { compose } from 'redux'

import Loader from "../../layout/Loader"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '10px',
    paddingTop:0
  },
  Main: {
    width:"100%"
  },
  Title:{
    paddingLeft: '10px',
  },
  loading:{
    textAlign:"center"
  },
  CarouselImage:{
      width:"100%",
      aspectRatio: "100/37"
  },
  activityTableHeader:{
    backgroundColor: "#343a40!important;",
    color:"#fff",
    fontWeight:"600",
    "& > *":{
        color:"#fff !important",
        fontWeight:"500",
    }
  },
  activityTableRow:{
    borderTop: "1px solid #343a40 ",
    color:"#444 !important",
    textDecoration:"none"
  },
  activityTableItem:{
    padding: "7px 10px",
    textDecoration:"none"
  },
  activityTableItemButton:{
    padding: "5px 10px",
    textDecoration:"none"
  },
  activityTableButton:{
    textDecoration:"none",
    borderColor: "#343a40 !important",
    color: "#343a40 !important",
    padding: "2ps 7px !important",
    "&:hover":{
        color: "#fff !important",
        backgroundColor:"#343a40 !important"
    }
  },
  LoadMoreBox:{
      textAlign:"center",
      marginTop:"20px"
  },
  LoadMoreButton:{
    textDecoration:"none",
    borderColor: "#343a40 !important",
    color: "#343a40 !important",
    padding: "2ps 7px !important",
    "&:hover":{
        color: "#fff !important",
        backgroundColor:"#343a40 !important"
    }
  },
  NoActiveDevices:{
      textAlign:"center",
  }

}));

const List = ({auth,user, DeviceData })=>{
    
    const classes = useStyles();

    const [limit, setLimit] = useState(100);

    
  useFirestoreConnect([
    { collection: 'Devices', storeAs: 'DeviceData', limit:limit, orderBy: ["lasttimeStamp", "desc"]}
  ])



        return (
            <main className={classes.Main}>
              <Box className={classes.Title}><h1 >Activities</h1></Box>  
        

              <HorizontalAds/>
              <br/>

<Grid className={classes.activityTableHeader} container rowSpacing={1}>
    <Grid className={classes.activityTableItem} item xs={12} sm={6} md={4}>
        Discription
    </Grid>
    <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
        Location
    </Grid>
    <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={2}>
        Time
    </Grid>
</Grid>

{ DeviceData && DeviceData.map(DeviceData => {
console.log(DeviceData);
var lasttimeStamp = "";
if(DeviceData.lasttimeStamp)
{
    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US');
    lasttimeStamp = timeAgo.format(DeviceData.lasttimeStamp.seconds*1000, 'time');
}

return (

    <Grid  className={classes.activityTableRow} component={NavLink}  to={"/activity/" + DeviceData.id} container >
        <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
        {DeviceData.name}
        </Grid>
        <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
        {DeviceData.ref ? DeviceData.ref : <Box className={classes.Unknown}>Unknown</Box>}
        </Grid>
        <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={2}>
        { lasttimeStamp }
        </Grid>
        <Grid className={classes.activityTableItemButton} xs={12}  item sm={6} md={2}>
            <Button  variant="outlined" className={classes.activityTableButton} size="small">View Device</Button>
        </Grid>
    </Grid>
)
})}

{!isLoaded(DeviceData)? <Loader/> : null}
{isEmpty(DeviceData)? <Box className={classes.NoActiveDevices}>No Active devices.</Box> : null}

            <Box className={classes.LoadMoreBox}>
                <Button  className={classes.LoadMoreButton} variant="outlined" onClick={()=>setLimit(limit+50)}>Load More</Button>
            </Box>
        <HorizontalAds/>
        </main>
        )
}


const mapStateToProps = (state) => {

    return {
        auth:firebase.auth(),
        DeviceData: state.firestore.ordered.DeviceData
    }
}

export default compose(
    connect(mapStateToProps),
    /*firestoreConnect(props  =>{
        if(props.match.path == "/account/activity" && props.auth.uid)
            return [
                { collection: 'Devices', storeAs: 'DeviceData', limit:100, where:[["users","array-contains",props.auth.uid]]},
            ]
        return [
            { collection: 'Devices', storeAs: 'DeviceData', limit:100, orderBy: ["lasttimeStamp", "desc"]},
        ]
    })*/
    /*firestoreConnect([
        { collection: 'Devices', storeAs: 'DeviceData', limit:100, orderBy: ["lasttimeStamp", "desc"]}
    ])*/
)(List);