import React from 'react'
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';

import Loader from "../../layout/Loader"

const Logout = () => {
    const firebase = getFirebase()
    firebase.auth().signOut().then(()=>{
        window.location.href = "/";
    })

    return <Loader/> //<Redirect to="/" />

    
}
export default Logout
