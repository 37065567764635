import React from 'react'



import {
    Box,
      } from '@mui/material';


import { makeStyles } from "@mui/styles/";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Battery",
    "Display":true,
    "document":{
        "url":"/widgets/battery",
        "label":"Battery Document"
    },
    "options":
    {
        "view":null,
        "settings":[
            {
              "id":"units",
              "type":"text",
              "name":"Units"
            }
          ],
          "grid":true
    }
}


const View = ({DeviceData,DevicesDetails,Settings}) => {
    const classes = useStyles();
    var Battery = "";
    if(DeviceData && DeviceData[0] && DeviceData[0].battery)
        Battery = DeviceData[0].battery
        
    if(DeviceData && DeviceData[0] && DeviceData[0].body.Battery)
        Battery = DeviceData[0].body.Battery
    
    if(DeviceData && DeviceData[0] && DeviceData[0].body.battery)
        Battery = DeviceData[0].body.battery
    
    if(DeviceData && DeviceData[0] && DeviceData[0].headers.battery)
        Battery = DeviceData[0].headers.battery
        
    
    return (
            <Box>
              {Battery} {Settings.units ? Settings.units : null}
            </Box>
        )
}



export default {
    Settings,
    View
};