import React, {Component } from 'react'

import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
loading:{
    textAlign: "center;",
    paddingTop: "20px;",
    marginBottom: "100px;",
    width:"100%"
},
CircularProgress:{

}
});

const Loader = (props) => {
    const classes = useStyles();
    return <Box className={classes.loading}>
        <CircularProgress size={100} thickness={10} className={classes.CircularProgress}/>
        <h2>Loading</h2>

    </Box>
}

export default (Loader);