import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import { AppBar,Toolbar,Typography,Box,IconButton } from '@mui/material';
import { NavLink } from 'react-router-dom'

import {Container,Button,Grid} from '@mui/material';

import { makeStyles } from '@mui/styles';
import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ... mainStyle,
  root:{
    textAlign:"center",
    padding: '30px',
    width:"100%"
  }
}))

const Page404 = () => {
    
    const classes = useStyles();
        return (
                <Box className={classes.root}>
                    <h1><strong>404.</strong> Could not find page.</h1>
                    <p>We could not find the page you were looking for.</p>

                    {/*<p>You may want to see:</p>

                    {/*<div class="row">
                                <div class="col-sm">
                                    <NavLink to="/" className="nav-link py-2 site-Link " id="" name="">Home</NavLink>
                                </div>
                                <div class="col-sm">
                                    <NavLink to="/stuff" className="nav-link py-2 site-Link  " id="" name="">Stuff</NavLink>
                                </div>
                                <div class="col-sm">
                                    <NavLink to="/stuff/add" className="nav-link py-2 site-Link " id="" name="">Add a broken stuff</NavLink>
                                </div>
                    </div> */}

<p>You may want to see:</p>
<Grid container spacing={2}>
                        <Grid item sm={4}>
                            <Button fullWidth fullWidth variant="outlined" component={NavLink} to="/documents">Read documents</Button>
                            
                        </Grid>
                        <Grid item sm={4}>
                            <Button fullWidth variant="outlined" component={NavLink}  to="/activity" >See the Data</Button>
                        </Grid>
                        <Grid item sm={4}>
                            <Button fullWidth variant="outlined" component={NavLink} to="/devices">See all the Devices</Button>
                        </Grid>
                    </Grid>
                    
                    {/*<ListStuff ListName="Recent Stuff"/>*/}
                    
                </Box>
        )
}
export default Page404
