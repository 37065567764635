import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const Footer = (props) => {

    const {user} = props

    //var user = firebase.auth().currentUser;

    const links = user == null ? <ul class="list-unstyled text-small"><li><NavLink class="text-muted" to="/login">Login</NavLink></li></ul>  :  <ul class="list-unstyled text-small"><li><NavLink class="text-muted" to="/control">Control</NavLink></li><li><NavLink class="text-muted" to="/account">Account</NavLink></li><li><NavLink class="text-muted" to="/logout">Logout</NavLink></li></ul>;


    return (
        <footer>
            <div class="border">
            <div class="container pt-5">
                <div class="row">
                <div class="col-6 col-md">
                    <NavLink to="/"><h5>Climate Sens</h5></NavLink>
                    <ul class="list-unstyled text-small">
                    <li><NavLink class="text-muted" to="/map">map</NavLink></li>
                    <li><NavLink class="text-muted" to="/devices">Devices</NavLink></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <NavLink to="/login"><h5>Account</h5></NavLink>
                    { links }
                </div>
                <div class="col-6 col-md">
                    <NavLink to="/documents"><h5>Docs</h5></NavLink>
                    <ul class="list-unstyled text-small">
                    <li><NavLink class="text-muted" to="/documents/gettingstarted">Getting started</NavLink></li>
                    <li><NavLink class="text-muted" to="/documents/devices">Devices</NavLink></li>
                    <li><NavLink class="text-muted" to="/documents/api">API</NavLink></li>
                    <li><NavLink class="text-muted" to="/documents/resources">Resources</NavLink></li>
                    </ul>
                </div>
                <div class="col-6 col-md">
                    <NavLink to="/about"><h5>About</h5></NavLink>
                    <ul class="list-unstyled text-small">
                    <li><NavLink class="text-muted" to="/contact">Contact</NavLink></li>
                    <li><NavLink class="text-muted" to="/terms">Terms</NavLink></li>
                    </ul>
                </div>
                </div>
            </div>
            </div> 


            <div class="copyFooter  pt-1 pb-1">
            <div class="container">
                <div class="hidden-xs text-left small">
                Copyright © 2020 <NavLink to="http://climatesens.com/">climatesens.com</NavLink>. All rights reserved.
                </div> 
            </div>
            </div>

        </footer>
    )
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser
    }
}

export default connect(mapStateToProps)(Footer)