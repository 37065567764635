import React from 'react'
import { NavLink } from 'react-router-dom'



import {Typography,Button} from '@mui/material';


import { makeStyles } from "@mui/styles/";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Welcome"
}


const View = (props) => {
    const classes = useStyles();
    const {Data } = props;

    return (
        <Typography variant="body2" color="textSecondary" component="p">
            <h3>Welcome to your dashboard.</h3>
            <br/>

            <Button  variant="outlined" component={NavLink} to="/documents/dashboard" >Read About Widgets</Button>

        </Typography>
        )
}



export default {
    Settings,
    View
};