import React from 'react'
//import firebase from 'firebase';
import { Box } from '@mui/material';


import { makeStyles } from '@mui/styles';
import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root:{
    textAlign:"center",
    padding: '30px',
    width:"100%"
  }
}))

const UnderConstrution = () => {
    
    const classes = useStyles();
        return (
                <Box className={classes.root}>
                    <h1>Under Construction</h1>
                    <p>Since Climate Sens is new, many pages have not been made.</p>
                </Box> 
        )
    
}
export default UnderConstrution
