import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider,useSelector } from 'react-redux'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import {getFirebase, isLoaded } from 'react-redux-firebase'
import fbConfig from './store/api/fbConfig'
import thunk from 'redux-thunk'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import firebase from 'firebase/app'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'


import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)

const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
});


const middleware = applyMiddleware(
    thunk.withExtraArgument({
        getFirebase,
        getFirestore
    })
);
  
const store = createStore(rootReducer,
  compose(
      middleware,
      //reactReduxFirebase(fbConfig,{attachAuthIsReady:true}),
      reduxFirestore(fbConfig)
  )
);

  
ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider
            firebase={firebase}
            config={fbConfig}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}
        >
            <App />
        </ReactReduxFirebaseProvider>
    </Provider>, document.getElementById('root')
);
  
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  //serviceWorker.unregister();
  

//ReactDOM.render(<React.Fragment><App/></React.Fragment>,document.getElementById('root'));
//<React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
