import React, {Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getFirebase } from 'react-redux-firebase';

import {Box,Button,Grid} from '@mui/material';


import HorizontalAds from "../layout/Ads/HorizontalAds"

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';import { compose } from 'redux'
import { connect } from 'react-redux'

import { makeStyles } from '@mui/styles';
import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  },
  loading:{
    textAlign:"center"
  }
}));

const  About =({user })=> {

    const classes = useStyles();


        return (
          <main className={classes.root} >
                    <article>
                        <h1>What are we doing?</h1>
                        <p>Climate Sens wants to investigate into the environment around us and compare it to other similar environment. Climate Sens has made devices and uses others devices or DIY devices to read the data from an environment. See the device list below.</p>
                        <p>Devices in your environment reads sensors and sends the readings to Climate Sens where is can be analyzed. The data from your devices is available to you in a easy readable graphs and can be downloaded. </p>
                    </article>
                    {/*<div class="row  justify-content-md-center">
                        <div class="col-sm-1 ">
                            <a href="https://www.openstreetmap.org/" target="_blank" class="">
                                <img src="/images/partners/Openstreetmap_logo.png" style={{width:'100%'}} />
                            </a>
                        </div>
                    </div>*/}
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                        {user ?
                            <Button fullWidth  variant="outlined" component={NavLink} to="/documents">Read documents</Button>
                            :
                            <Button fullWidth  variant="outlined" component={NavLink} to="/login">Join in here</Button>
                        }
                        </Grid>
                        <Grid item sm={4}>
                            <Button fullWidth variant="outlined" component={NavLink}  to="/activity" >See the Data</Button>
                        </Grid>
                        <Grid item sm={4}>
                            <Button fullWidth variant="outlined" component={NavLink} to="/devices">See the Devices</Button>
                        </Grid>
                    </Grid>
                    
<HorizontalAds/>

<Box>

<h2>Climate sens</h2>
<p>Building and running a site like this takes at lot of time to get it dont correctly.</p>
<p>The list below is the out standing tasks to be completed</p>
<ul>
    <li>Improve API</li>
    <li>Improve Permissions</li>
    <li>More documentations</li>
    <li>More devices details</li>
    <li>Develop devices</li>
    <li>Develop more dashboard widgets</li>
    <li>Make shop</li>
    <li>Link site</li>
    <li>Test</li>
    <li>Set up notifications</li>
</ul>
</Box>
            </main>
        )
}

const mapStateToProps = (state) => {

    return {
        user:firebase.auth().currentUser
    }
}

export default compose(
    connect(mapStateToProps)
)(About);
