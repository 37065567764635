import React from 'react'



import {
    Box,
      } from '@mui/material';


import { makeStyles } from "@mui/styles/";
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});




const Settings = {
    "Name":"Value",
    "Display":true,
    "options":
    {
        "view":null,
        "settings":[
            {
              "id":"valueName",
              "type":"text",
              "name":"Name"
            },
            {
              "id":"value",
              "type":"text",
              "name":"Value"
            },
            {
              "id":"unit",
              "type":"text",
              "name":"Unit"
            }
          ],
        "grid":true,
    }
}


const View = ({DeviceData,DevicesDetails,Settings}) => {
    const classes = useStyles();
    var Value = "";

    if(DeviceData && DeviceData[0] && DeviceData[0].body[Settings.value])
        Value = DeviceData[0].body[Settings.value]
    else
    
        return (
            <Box>
                {Settings.valueName ? <strong>{Settings.valueName}: </strong>: null}
                Unknown
            </Box>
        )
    
    return (
            <Box>
                {Settings.valueName ? <strong>{Settings.valueName}: </strong>: null}
                {Value} {Settings.unit ? Settings.unit : null}
            </Box>
        )
}



export default {
    Settings,
    View
};