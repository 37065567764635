// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyCV37FcdRaguEiuuWRI7vNGW7Sod63GmHs",
  authDomain: "climate-sens.firebaseapp.com",
  databaseURL: "https://climate-sens.firebaseio.com",
  projectId: "climate-sens",
  storageBucket: "climate-sens.appspot.com",
  messagingSenderId: "787679547901",
  appId: "1:787679547901:web:71e095e712951a3a014994",
  measurementId: "G-DP6DH5Y206"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth;
//firebase.messaging();

export default firebase;
export { db };