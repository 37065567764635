import React, {Component ,useState} from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import axios from 'axios'

import {Divider ,TextField,Grid,Button,Box} from '@mui/material';

import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useParams, useLocation ,useNavigate  } from 'react-router-dom'

import {Chip,Alert,Switch} from '@mui/material';
import { makeStyles } from "@mui/styles"
import mainStyle from "../../assets/mainStyle";
import Page404 from '../Page404';
import Loader from '../../layout/Loader';
import Login from '../auth/Login';

const useStyles = makeStyles({
...mainStyle,
DeviceOptions:{
    marginBottom:"20px"
}
})



const AddDevice = ({user,DeviceDetails,Loaded,Empty}) => {

    const classes = useStyles();

    let { deviceId } = useParams();
    const navigate = useNavigate()
    const [values, setvalues] = useState({DeviceId:deviceId,private:false});
    const [responseValues, setResponseValues] = useState(null);
    const [{loading, error,saved}, setStatus] = useState({});

    useFirestoreConnect([
        { collection: 'DeviceTypes', storeAs: 'DeviceKey', doc:deviceId},
    ])
    
    const handleChange = (e) => {
        setvalues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleClear = (e) => {
        setvalues({DeviceId:deviceId,private:false})
        setResponseValues(null)
        setStatus({})
    }

    

    const handleSubmit= () =>
    {
        //event.preventDefault();
        
        setStatus({loading:true})

        firebase.auth().currentUser.getIdToken().then(function(idToken) {
          axios.post('https://api.climatesens.com/add/configure', values, { "headers":{"Authorization": idToken}})
          .then(function (response) {

            console.log("response");
            console.log(response.data);

            var ResponseValuesData = {}
            if(response.data.DeviceId && response.data.Key && response.data.Pin)
            {
                ResponseValuesData.DeviceId = response.data.DeviceId
                ResponseValuesData.Key = response.data.Key
                ResponseValuesData.Pin = response.data.Pin

                setResponseValues(ResponseValuesData)
                return
            }
            setStatus({error:"Something went wrong"})
          })
          .catch(function (error) {
              console.log("error");
              console.log(error);
              setStatus({error:"Server problem try again later"})
          })
          .finally(function () {

          }); 
        }).catch(function(error) {
         // if(timeoutTimer)
          //    clearInterval(timeoutTimer);
              setStatus({error:"User validation problem"})
        });
    }



    



    if(!user)
        return(<Login/>)

    if(!Loaded)
        return(<Loader/>)


    if(!DeviceDetails || Empty || !deviceId || DeviceDetails.locked)
        return(<Page404/>)


    if(responseValues)
    {
        return(<main className={classes.root}>
            <h2>Connect Your device </h2>
            <h3><strong>Device:</strong> {DeviceDetails.name}</h3>
            <p>Please Copy and save the id, key and pin in a save place. your device will need to use these details to connect to Climate Sens. </p>
            <TextField margin="normal" disabled fullWidth value={responseValues.DeviceId} className={classes.TextField} variant="outlined" label="Id" />
            <TextField margin="normal" disabled fullWidth value={responseValues.Key}  className={classes.TextField} variant="outlined" label="Key" />
            <TextField margin="normal" disabled fullWidth value={responseValues.Pin} className={classes.TextField} variant="outlined" label="Pin" />
            <p>The pin will allow other to connect to this device</p>
            <Button  variant="contained" sx={{marginRight:"10px"}}  component={NavLink} to={"/activity/"+responseValues.DeviceId}>Go to device</Button>
            <Button  variant="contained" onClick={handleClear}>Add/connect new device</Button>
        </main>)
    }


    return (
        <main className={classes.root}>
            <h2>Connect Your device </h2>
            <h3><strong>Device:</strong> {DeviceDetails.name}</h3>
            <p>Click the button below to generate keys for your device.</p>
            

            {error || loading ?
                <Box sx={{height:"50px"}}>
                    {error ? <Alert severity="error">Error — {error==true ? "There was an error." : error}</Alert> :null}
                    {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                </Box>
            :
            <Box>
                <TextField margin="normal" fullWidth value={"name" in values ? values.name : null} onChange={handleChange} name="name" className={classes.TextField} variant="outlined" label="Name" />
                <TextField margin="normal" fullWidth value={"location" in values ? values.location : null} onChange={handleChange} name="location" className={classes.TextField} variant="outlined" label="Location" />
                <Button  variant="contained" onClick={handleSubmit}>Generate keys</Button>
            </Box>
            }

        </main>
    )
}

const mapStateToProps = (state) => {

    var DeviceDetails = null;
    if(state.firestore.ordered.DeviceKey)
        DeviceDetails = state.firestore.ordered.DeviceKey[0];

    return {
        user:firebase.auth().currentUser,
        DeviceDetails:DeviceDetails,
        Loaded:isLoaded(state.firestore.ordered.DeviceKey),
        Empty:isEmpty(state.firestore.ordered.DeviceKey) 
    }
}

export default compose(
    connect(mapStateToProps)
)(AddDevice);