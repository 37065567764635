import React, {Component,useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import DeviceList from './devices/List'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
//import { firestore } from 'firebase'
import { NavLink } from 'react-router-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Chart from "chart.js";
import {  isEmpty, isLoaded } from 'react-redux-firebase'


import {Box,Button,Grid} from '@mui/material';
import Carousel from 'react-material-ui-carousel'


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { compose } from 'redux'

import ListDevices from "../layout/Device/ListDevices"
import ListSponsors from "../layout/Sponsors/ListSponsors"
import Loader from "../layout/Loader"



import { makeStyles } from '@mui/styles';
import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    paddingTop:0
  },
  Main: {
    width:"100%"
  },
  loading:{
    textAlign:"center"
  },
  CarouselImage:{
      width:"100%",
      aspectRatio: "100/37"
  },
  activityTableHeader:{
    backgroundColor: "#343a40!important;",
    color:"#fff",
    fontWeight:"600",
    "& > *":{
        color:"#fff !important",
        fontWeight:"500",
    }
  },
  activityTableRow:{
    borderTop: "1px solid #343a40 ",
    color:"#444 !important",
    textDecoration:"none"
  },
  activityTableItem:{
    padding: "7px 10px",
    textDecoration:"none"
  },
  activityTableItemButton:{
    padding: "5px 10px",
    textDecoration:"none"
  },
  activityTableButton:{
    textDecoration:"none",
    borderColor: "#343a40 !important",
    color: "#343a40 !important",
    padding: "2ps 7px !important",
    "&:hover":{
        color: "#fff !important",
        backgroundColor:"#343a40 !important"
    }
  }

}));

const Index = ({user,Sponsors,deviceTypesCS, deviceTypesCommercial, deviceTypesDIY, DeviceData }) => {

    const classes = useStyles();


return(
    <main className={classes.Main}>

        <Carousel IndicatorIcon={null} animation="slide" duration={1000}>
            <img className={classes.CarouselImage} src="/images/banner_image-01.svg"  /*width="939" height="500"*/ />
            <img className={classes.CarouselImage} src="/images/banner_image-02.svg"  /* width="939" height="500"*/ />
            <img className={classes.CarouselImage} src="/images/banner_image-03.svg"  /* width="939" height="500"*/ />
        </Carousel>

        <Box  className={classes.root} >

            <article>
                <h1>What are we doing?</h1>
                <p>Climate Sens wants to investigate into the environment around us and compare it to other similar environment. Climate Sens has made devices and uses others devices or DIY devices to read the data from an environment. See the device list below.</p>
                <p>Devices in your environment reads sensors and sends the readings to Climate Sens where is can be analyzed. The data from your devices is available to you in a easy readable graphs and can be downloaded. </p>
            </article>
                    {/*<div class="row  justify-content-md-center">
                        <div class="col-sm-1 ">
                            <a href="https://www.openstreetmap.org/" target="_blank" class="">
                                <img src="/images/partners/Openstreetmap_logo.png" style={{width:'100%'}} />
                            </a>
                        </div>
                    </div>*/}
                   <Grid container spacing={2}>
                        <Grid item sm={4}>
                        {user ?
                            <Button fullWidth fullWidth variant="outlined" component={NavLink} to="/documents">Read documents</Button>
                            :
                            <Button fullWidth fullWidth variant="outlined" component={NavLink} to="/login">Join in here</Button>
                        }
                        </Grid>
                        <Grid item sm={4}>
                            <Button fullWidth variant="outlined" component={NavLink}  to="/activity" >See the Data</Button>
                        </Grid>
                        <Grid item sm={4}>
                            <Button fullWidth variant="outlined" component={NavLink} to="/devices">See all the Devices</Button>
                        </Grid>
                    </Grid>

        <h1 >Devices</h1>
        
                
        {!isLoaded(deviceTypesCS,deviceTypesCommercial,deviceTypesDIY) ? <Loader/> : <Box>
            <ListDevices Devices={deviceTypesCS}/>
            <ListDevices Devices={deviceTypesCommercial}/> 
            <ListDevices Devices={deviceTypesDIY}/> 
<br/>
            <Button fullWidth variant="outlined" component={NavLink} to="/devices">See all the Devices</Button>

        </Box>}



        <h1>Activities</h1>


        <Grid className={classes.activityTableHeader} container rowSpacing={1}>
            <Grid className={classes.activityTableItem} item xs={12} sm={6} md={4}>
                Discription
            </Grid>
            <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
                Location
            </Grid>
            <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={2}>
                Time
            </Grid>
        </Grid>

    { DeviceData && DeviceData.map(DeviceData => {
        var lasttimeStamp = "";
        if(DeviceData.lasttimeStamp)
        {
            TimeAgo.addLocale(en)
            const timeAgo = new TimeAgo('en-US');
            lasttimeStamp = timeAgo.format(DeviceData.lasttimeStamp.seconds*1000, 'time');
        }

        return (

            <Grid  className={classes.activityTableRow} component={NavLink}  to={"/activity/" + DeviceData.id} container >
                <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
                    {DeviceData.name}
                </Grid>
                <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
                    {DeviceData.ref ? DeviceData.ref : <Box className={classes.Unknown}>Unknown</Box>}
                </Grid>
                <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={2}>
                    { lasttimeStamp }
                </Grid>
                <Grid className={classes.activityTableItemButton} xs={12}  item sm={6} md={2}>
                    <Button  variant="outlined" className={classes.activityTableButton} size="small">View Device</Button>
                </Grid>
            </Grid>
        )
    })}

<br/>
        <Button fullWidth variant="outlined" component={NavLink} to="/activity">View More</Button>





        <Box className={classes.tiltBlock}>
        <h1>Sponsors</h1>
        <p>These are the sponsors that help to run and supply devices to gather data</p>
      </Box>
        <ListSponsors Sponsors={Sponsors}/>
            <br/>
            <Button fullWidth variant="outlined" component={NavLink} to="/sponsors">See all the Sponsors</Button>
            <br/>
            <br/>
            <Button fullWidth variant="outlined" component={NavLink} to="/sponsors/apply">Become a Sponsors</Button>
            </Box>
        </main>
);

}






const mapStateToProps = (state) => {

    return {
        user:firebase.auth().currentUser,
        deviceTypesCS: state.firestore.ordered.deviceTypesCSHome,
        deviceTypesCommercial: state.firestore.ordered.deviceTypesCommercialHome,
        deviceTypesDIY: state.firestore.ordered.deviceTypesDIYHome,
        DeviceData: state.firestore.ordered.DeviceDataHome,
        Sponsors: state.firestore.ordered.Sponsors,
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect([
        
        { collection: 'Sponsors', storeAs: 'Sponsors'},
        { collection: 'DeviceTypes', storeAs: 'deviceTypesCSHome', limit:4, where:[["category","==","CS"],["display","==",true]]},
        { collection: 'DeviceTypes', storeAs: 'deviceTypesCommercialHome', limit:4, where:[["category","==","Commercial"],["display","==",true]]},
        { collection: 'DeviceTypes', storeAs: 'deviceTypesDIYHome', limit:4, where:[["category","==","DIY"],["display","==",true]]},
        { collection: 'Devices', storeAs: 'DeviceDataHome', limit:10, orderBy: ["lasttimeStamp", "desc"]}
    ])
)(Index);