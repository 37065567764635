import { Link, Redirect } from 'react-router-dom'
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import React, {Component,useState } from 'react'
//import DeviceList from './devices/List'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
//import { firestore } from 'firebase'
import { NavLink } from 'react-router-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Chart from "chart.js";


import parse from 'html-react-parser';
import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'


import {Divider ,Grid,Button,Chip} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Loader from "../../layout/Loader"
import Page404 from "../Page404"

import HorizontalAds from "../../layout/Ads/HorizontalAds"

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useParams, useLocation ,useNavigate  } from 'react-router-dom'


import { makeStyles } from "@mui/styles"
import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
root: {
    
    padding: '30px',
    width:"100%"
  },
  ImageGalleryBox:{
    width:"300px",
    minWidth: "300px;",
    display:"block",
    ['@media (max-width:899px)']: { // eslint-disable-line no-useless-computed-key
        width: '100%',
        minWidth: "100%;",
    }
},
Image:{
    width:"300px",
    minWidth: "300px;",
    display:"block",
},
TitleBlock:{
  marginBottom:"30px"
},
Name:{
  margin:0,
  //topMargin:"10px",
  marginBottom:"0px"
},
GridLayout:{
  display: "flex;",
  position: "relative;",
  //alignItems: "center;",
},
MainText:{
  flexGrow:1
},
CodeBlock:{
  overflow: "scroll;",
  height: "90%;",
  maxHeight: "400px;",
  background: "#eee;",
  fontSize: "15px;",
  lineHeight: "1.6;",
  maxWidth: "100%;",
  padding: "1.6em;",
},
Main: {
  width:"100%"
},
Title:{
  paddingLeft: '10px',
},
loading:{
  textAlign:"center"
},
CarouselImage:{
    width:"100%",
    aspectRatio: "100/37"
},
activityTableHeader:{
  backgroundColor: "#343a40!important;",
  color:"#fff",
  fontWeight:"600",
  "& > *":{
      color:"#fff !important",
      fontWeight:"500",
  }
},
activityTableRow:{
  borderTop: "1px solid #343a40 ",
  color:"#444 !important",
  textDecoration:"none"
},
activityTableItem:{
  padding: "7px 10px",
  textDecoration:"none"
},
activityTableItemButton:{
  padding: "5px 10px",
  textDecoration:"none"
},
activityTableButton:{
  textDecoration:"none",
  borderColor: "#343a40 !important",
  color: "#343a40 !important",
  padding: "2ps 7px !important",
  "&:hover":{
      color: "#fff !important",
      backgroundColor:"#343a40 !important"
  }
},
LoadMoreBox:{
    textAlign:"center",
    marginTop:"20px"
},
LoadMoreButton:{
  textDecoration:"none",
  borderColor: "#343a40 !important",
  color: "#343a40 !important",
  padding: "2ps 7px !important",
  "&:hover":{
      color: "#fff !important",
      backgroundColor:"#343a40 !important"
  }
},
NoActiveDevices:{
    textAlign:"center",
},

deviceTag:{
    marginRight:"5px",
    marginTop:"5px",
  },
  Activities:{
      marginBottom:"50px"
  },
  CostsBox:{
    width:"100%",
    display:"flex"
  },
  Price:{
    fontSize:"22px",
    margin:"2px 20px "
    }
})


function View({DeviceDetails,user,Loaded,Empty,DeviceData,LoadedDeviceData}) {  
    const classes = useStyles();
    let { id } = useParams();
    
    const [limit, setLimit] = useState(30);

    TimeAgo.addLocale(en)
    const timeAgo = new TimeAgo('en-US');

    useFirestoreConnect([
        { collection: 'DeviceTypes', storeAs: 'DeviceDetails', doc:id},
        { collection: 'Devices', storeAs: 'DeviceData', where:[["type","==",id]], limit:limit, orderBy: ["lasttimeStamp", "desc"]}
    ])
    

if(!Loaded)
    return(<Loader/>)
  
  if(Empty || id == null )
    return(<Page404/>)
  

    
    var created = null
    if(DeviceDetails.created)
        created = new Date(DeviceDetails.created.seconds*1000)


    
  return (
    <Box className={classes.root}>
          <HorizontalAds/>

      <Box className={classes.MainText} display={{xs:"block",sm:"block",md:"none"}}>
        <Box className={classes.TitleBlock}>
          <h2 className={classes.Name}>{DeviceDetails.name ? DeviceDetails.name:null}</h2>
          
          {/*categoriesList && categoriesList.length > 0 ? <Box>
                <strong>Categories:</strong> 
                  { categoriesList.map((item, index) =>{
                    return (<>{index > 0 ? "," : null} <Link  to={"/category/"+item.slug}>{item.name}</Link ></>)
                  })}
                </Box> : null*/}
                {DeviceDetails.category ?<Box>{DeviceDetails.category}</Box>:null}

                <Box className={classes.deviceTags}> 
                    { DeviceDetails.tags && DeviceDetails.tags.map(tag => { return (<Chip  className={classes.deviceTag} label={tag} />) })}
                </Box>
              
                        {DeviceDetails.created ? <Box><strong>Date created:</strong> {created.toUTCString()} ({timeAgo.format(DeviceDetails.created.seconds*1000, 'time')})</Box> : null}

                        {DeviceDetails.private ? <Box><strong>Private:</strong> True</Box> : null}
              


                        <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                        {DeviceDetails.admin && user && DeviceDetails.admin.includes(user.uid) ?<Button sx={{marginRight:"10px"}} component={NavLink} to={"/device/"+id+"/edit"} variant="contained">Edit</Button>:null}
                        {DeviceDetails.locked ? null: <Button component={NavLink}  to={"/add/" + DeviceDetails.id} variant="contained">Add this device</Button>}

                        </Box>
                          
                        <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                        {DeviceDetails.purchasable && DeviceDetails.stock != null  && DeviceDetails.price != null  ? <Box  className={classes.CostsBox}>
                                  <Box>
                                    <Button component={NavLink}  to={"/device/" + DeviceDetails.id} variant="outlined">Buy</Button>
                                  </Box>
                                  <Box className={classes.Price} >
                                    {DeviceDetails.stock <= 0 ? "Out of stock" : DeviceDetails.price > 0 ? "$"+DeviceDetails.price:"FREE"}
                                  </Box>

                                </Box>
                                :null}
                                { DeviceDetails.url ? <Button variant="outlined" sx={{marginRight:"10px"}} target="_blank" href={DeviceDetails.url}>Link</Button>
                                  :null}
                                  {
                                  DeviceDetails.urlBuy ? <Button variant="outlined" sx={{marginRight:"10px"}} target="_blank" href={DeviceDetails.urlBuy}>Buy Externally</Button>
                                  :null}
                                  {
                                  DeviceDetails.urlMake ? <Button variant="outlined" sx={{marginRight:"10px"}} target="_blank" href={DeviceDetails.urlMake}>Make</Button>
                                  :
                                  null    
                                }

</Box>
                {/*DeviceDetails && (DeviceDetails.url ||DeviceDetails.client ||DeviceDetails.product)   ? <Divider /> : null}
                {DeviceDetails && DeviceDetails.url  ? <Box><a target="_blank" rel="noreferrer" href={DeviceDetails.url}>{DeviceDetails.url}</a ></Box> : null}
                {DeviceDetails && DeviceDetails.client  ? <Box><a target="_blank" rel="noreferrer" href={DeviceDetails.client}>{DeviceDetails.client}</a ></Box> : null}
                {DeviceDetails && DeviceDetails.product  ? <Box><strong>product:</strong> <a target="_blank" rel="noreferrer" href={DeviceDetails.product}>{DeviceDetails.product}</a ></Box> : null*/}

              </Box>
      </Box>





      <Box className={classes.GridLayout}>
          {DeviceDetails && DeviceDetails.image ?
            <>
              <Box className={classes.ImageGalleryBox}>
                  {/*<ImageGallery projectDetails={DeviceDetails ? DeviceDetails:null} Attachments={ImageGalleryImages}/>*/}
                  <img  className={classes.Image} src={DeviceDetails.image} alt={DeviceDetails.name+" Image"}/>
              </Box>
              <Box sx={{width:"40px"}} display={{xs:"none",sm:"none",md:"block"}}/>
            </>
          :null}
          

          <Box className={classes.MainText} display={{xs:"none",sm:"none",md:"block"}}>
        <Box className={classes.TitleBlock}>
              <h2 className={classes.Name}>{DeviceDetails.name ? DeviceDetails.name:null}</h2>
              
              {/*categoriesList && categoriesList.length > 0 ? <Box>
                <strong>Categories:</strong> 
                  { categoriesList.map((item, index) =>{
                    return (<>{index > 0 ? "," : null} <Link  to={"/category/"+item.slug}>{item.name}</Link ></>)
                  })}
                </Box> : null*/}
                {DeviceDetails.category ?<Box> {DeviceDetails.category}</Box>:null}
                
                <Box className={classes.deviceTags}> 
                    { DeviceDetails.tags && DeviceDetails.tags.map(tag => { return (<Chip  className={classes.deviceTag} label={tag} />) })}
                </Box>

                        {DeviceDetails.created ? <Box><strong>Date created:</strong> {created.toUTCString()} ({timeAgo.format(DeviceDetails.created.seconds*1000, 'time')})</Box> : null}

                        {DeviceDetails.private ? <Box><strong>Private:</strong> True</Box> : null}
                        

                        <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                        {DeviceDetails.admin && user && DeviceDetails.admin.includes(user.uid) ?<Button   sx={{marginRight:"10px"}} component={NavLink} to={"/device/"+id+"/edit"} variant="contained">Edit</Button>:null}
                        {DeviceDetails.locked ? null: <Button component={NavLink}  to={"/add/" + DeviceDetails.id} variant="contained">Add this device</Button>}

                        </Box>
                          
                          <Box  sx={{marginBottom:"10px",marginTop:"10px"}}>
                        {DeviceDetails.purchasable && DeviceDetails.stock != null  && DeviceDetails.price != null  ? <Box  className={classes.CostsBox}>
                        <Box>
                                    <Button component={NavLink}  to={"/device/" + DeviceDetails.id} variant="outlined">Buy</Button>
                                  </Box>
                                  <Box className={classes.Price} >
                                    {DeviceDetails.stock <= 0 ? "Out of stock" : DeviceDetails.price > 0 ? "$"+DeviceDetails.price:"FREE"}
                                  </Box>

                                </Box>
                                
                                :null}
                                { 
                                  DeviceDetails.url ? <Button variant="outlined" target="_blank" sx={{marginRight:"10px"}} href={DeviceDetails.url}>Link</Button>
                                  :null}
                                  {
                                  DeviceDetails.urlBuy ? <Button variant="outlined" target="_blank" sx={{marginRight:"10px"}} href={DeviceDetails.urlBuy}>Buy Externally</Button>
                                  :null}
                                  {
                                  DeviceDetails.urlMake ? <Button variant="outlined" target="_blank" sx={{marginRight:"10px"}} href={DeviceDetails.urlMake}>Make</Button>
                                  :
                                  null    
                                }

</Box>

                {/*DeviceDetails && (DeviceDetails.url ||DeviceDetails.client ||DeviceDetails.product)   ? <Divider /> : null}
                {DeviceDetails && DeviceDetails.url  ? <Box><a target="_blank" rel="noreferrer" href={DeviceDetails.url}>{DeviceDetails.url}</a ></Box> : null}
                {DeviceDetails && DeviceDetails.client  ? <Box><a target="_blank" rel="noreferrer" href={DeviceDetails.client}>{DeviceDetails.client}</a ></Box> : null}
                {DeviceDetails && DeviceDetails.product  ? <Box><strong>product:</strong> <a target="_blank" rel="noreferrer" href={DeviceDetails.product}>{DeviceDetails.product}</a ></Box> : null*/}



              
          </Box>
              <Box className={classes.body}>
                  {DeviceDetails.description ? parse(DeviceDetails.description):null}
              </Box>

              
              {DeviceDetails.inputs ? <Box>
                                <h2>Inputs</h2>
                                <ul>
                                    { Object.keys(DeviceDetails.inputs).map(input => {
                                        return (<li>{input}</li>)
                                    })}
                                </ul>  
                            </Box> : null}

          </Box>
      </Box>


      <Box>
        {/* ProjectsMeta && ProjectsMeta.map((item)=>{
          switch (item.type) {
            case "code":
              return <Box>
                        <h2>{item.title?item.title:"Code for Project"}</h2>
                        <a  rel="noreferrer" target="_blank" href="https://www.gnu.org/licenses/gpl-3.0.en.html">GNU General Public License v3.0</a>
                        <pre className={classes.CodeBlock}>{item.code}</pre>
                      </Box>

            case "youtube":
              return null

            /*case "file":
              return null
              break;* * /

            default:
              break;
          }

          return null
        })*/}
      </Box>

      

        <Box className={classes.body}  display={{xs:"block",sm:"block",md:"none"}}>
            {DeviceDetails.description ? parse(DeviceDetails.description):null}
        </Box>

              
              {DeviceDetails.inputs ? <Box  display={{xs:"block",sm:"block",md:"none"}}>
                                <h2>Inputs</h2>
                                <ul>
                                    { Object.keys(DeviceDetails.inputs).map(input => {
                                        return (<li>{input}</li>)
                                    })}
                                </ul>  
                            </Box> : null}












        <HorizontalAds/>

<Box className={classes.Activities}>
        <Box className={classes.Title}><h1 >Activities</h1></Box>  
        


        <Grid className={classes.activityTableHeader} container rowSpacing={1}>
            <Grid className={classes.activityTableItem} item xs={12} sm={6} md={4}>
                Discription
            </Grid>
            <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
                Location
            </Grid>
            <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={2}>
                Time
            </Grid>
        </Grid>
        
        { DeviceData && DeviceData.map(DeviceData => {
        console.log(DeviceData);
        var lasttimeStamp = "";
        if(DeviceData.lasttimeStamp)
        {
            TimeAgo.addLocale(en)
            const timeAgo = new TimeAgo('en-US');
            lasttimeStamp = timeAgo.format(DeviceData.lasttimeStamp.seconds*1000, 'time');
        }
        
        return (
        
            <Grid  className={classes.activityTableRow} component={NavLink}  to={"/activity/" + DeviceData.id} container >
                <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
                {DeviceData.name}
                </Grid>
                <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={4}>
                {DeviceData.ref ? DeviceData.ref : <Box className={classes.Unknown}>Unknown</Box>}
                </Grid>
                <Grid className={classes.activityTableItem}  item xs={12} sm={6} md={2}>
                { lasttimeStamp }
                </Grid>
                <Grid className={classes.activityTableItemButton} xs={12}  item sm={6} md={2}>
                    <Button  variant="outlined" className={classes.activityTableButton} size="small">View Device</Button>
                </Grid>
            </Grid>
        )
        })}
        
        {!isLoaded(DeviceData)? <Loader/> : null}
        {isEmpty(DeviceData)? <Box className={classes.NoActiveDevices}>No Active devices.</Box> : null}
        
                    <Box className={classes.LoadMoreBox}>
                        <Button  className={classes.LoadMoreButton} variant="outlined" onClick={()=>setLimit(limit+50)}>Load More</Button>
                        </Box>
        </Box>







        <HorizontalAds/>
    </Box>);







/*
        return (
            <Box className={classes.Device}>
                            <Box className={classes.DeviceBody}>
                                <Box className={classes.DeviceDetails}>
                                    <NavLink  className={classes.deviceTitle} to={"/device/" + DeviceDetails.id}>{DeviceDetails.name}</NavLink>
                                    <Box className={classes.deviceTags}> 
                                        { DeviceDetails.tags && DeviceDetails.tags.map(tag => { return (<Chip  className={classes.deviceTag} label={tag} />) })}
                                    </Box>
                                    <p>{DeviceDetails.category}</p>
                                    <p>{DeviceDetails.excerpt}</p>
                                </Box>
                                {DeviceDetails.image != null && DeviceDetails.image != "" ? <img src={DeviceDetails.image}  /*onerror="this.onerror=null; this.src='/images/no-image-available.png'"* /  className={classes.DeviceImage}/> : null}
                            </Box> 

                            <Box className={classes.DeviceActions}>
                                <Button component={NavLink}  to={"/device/" + DeviceDetails.id} variant="outlined">See more</Button>
                                <Box className={classes.spacer}/>
{DeviceDetails.purchasable && DeviceDetails.stock != null  && DeviceDetails.price != null  ? <Box  className={classes.CostsBox}>
                                  
                                  <Box className={classes.Price} >{DeviceDetails.stock <= 0 ? "Out of stock" : DeviceDetails.price > 0 ? "$"+DeviceDetails.price:"FREE"}</Box>
                                  <Button component={NavLink}  to={"/device/" + DeviceDetails.id} variant="outlined">Buy</Button>

                                </Box>
                                :
                                  DeviceDetails.url ? <Button variant="outlined" target="_blank" href={DeviceDetails.url}>Link</Button>
                                  :
                                  DeviceDetails.urlBuy ? <Button variant="outlined" target="_blank" href={DeviceDetails.urlBuy}>Buy Externally</Button>
                                  :
                                  DeviceDetails.urlMake ? <Button variant="outlined" target="_blank" href={DeviceDetails.urlMake}>Make</Button>
                                  :
                                  null    
                                }                            </Box>
                        </Box>
        )*/









}



const mapStateToProps = (state) => {

    var DeviceDetails = null;
    if(state.firestore.ordered.DeviceDetails)
        DeviceDetails = state.firestore.ordered.DeviceDetails[0];

    return {
        user:firebase.auth().currentUser,
        DeviceDetails:DeviceDetails,
        DeviceData:state.firestore.ordered.DeviceData,
        Loaded:isLoaded(state.firestore.ordered.DeviceDetails),
        Empty:isEmpty(state.firestore.ordered.DeviceDetails) 
    }
}

export default compose(
    connect(mapStateToProps),
)(View);