import AdSense from 'react-adsense';
import React from 'react'
import { NavLink } from 'react-router-dom'

import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import {Select,MenuItem,Grid,Alert,Button,Chip,Card,CardActionArea,CardContent,Typography} from '@mui/material';


import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
  DeviceBox:{
  },
  DeviceDetails:{
    flexGrow:1
  },
  DeviceImage:{
    width:"150px",
    height:"150px",
    objectFit: "scale-down;",
    ['@media (max-width:550px)']: { 
        texta:"block !important;",
        marginTop:"10px"
    }
  },
  deviceTags:{

  },
  deviceTag:{
    marginRight:"5px",
    marginTop:"10px",
  },
  DeviceActions:{
    display:"flex",
    marginTop:"10px"
  },
  DeviceBody:{
    display:"flex",
    ['@media (max-width:550px)']: { 
        display:"block !important;"
    }
  },
  Device:{
    borderBottom:"1px solid #444;",
    padding:"20px 0"
  },
  deviceTitle:{
      color:"#333",
      fontSize:"24px",
      textDecoration:"none",
      "&:hover":{
        textDecoration:"underline",
      }
  },
  CostsBox:{
    display:"flex"
  },
  Price:{
    padding:"5px 15px"
  },
  deviceImageBox:{
    ['@media (max-width:550px)']: { 
        width:"100%",
        textAlign: "center;"
    }
  },
});

const ListDevices = ({Devices}) => {
    const classes = useStyles();
    
    console.log(Devices)
    return (
        <Box>
            { Devices && Devices.map(device => {
                return ( <Box className={classes.Device}>
                            <Box className={classes.DeviceBody}>
                                <Box className={classes.DeviceDetails}>
                                    <NavLink  className={classes.deviceTitle} to={"/device/" + device.id}>{device.name}</NavLink>
                                    <Box className={classes.deviceTags}> 
                                        { device.tags && device.tags.map(tag => { return (<Chip  className={classes.deviceTag} label={tag} />) })}
                                    </Box>
                                    <p>{device.category}</p>
                                    <p>{device.DeviceTypes}</p>
                                </Box>
                                

                                {device.image != null && device.image != "" ? <Box className={classes.deviceImageBox}><img src={device.image}  /*onerror="this.onerror=null; this.src='/images/no-image-available.png'"*/  className={classes.DeviceImage}/></Box> : null}
                            </Box> 

                            <Box className={classes.DeviceActions}>
                            <Button component={NavLink}  to={"/device/" + device.id} variant="outlined">See more</Button>

                            {device.locked ? null: <Button sx={{marginLeft:"10Px"}} component={NavLink}  to={"/add/" + device.id} variant="contained">Add this device</Button>}

                            
                                <Box className={classes.spacer}/>

                                {device.purchasable && device.stock != null  && device.price != null  ? <Box  className={classes.CostsBox}>
                                  
                                  <Box className={classes.Price} >{device.stock <= 0 ? "Out of stock" : device.price > 0 ? "$"+device.price:"FREE"}</Box>
                                  <Button component={NavLink}  to={"/device/" + device.id} variant="outlined">Buy</Button>

                                </Box>
                                :
                                  device.url ? <Button variant="outlined" target="_blank" href={device.url}>Link</Button>
                                  :
                                  device.urlBuy ? <Button variant="outlined" target="_blank" href={device.urlBuy}>Buy Externally</Button>
                                  :
                                  device.urlMake ? <Button variant="outlined" target="_blank" href={device.urlMake}>Make</Button>
                                  :
                                  null    
                                }
                                
                          </Box>
                        </Box>
                )
            })}
        </Box>)
}

export default (ListDevices);