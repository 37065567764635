import { NavLink } from 'react-router-dom'
import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
//import { firestore } from 'firebase'
import { reduxFirestore, getFirestore } from 'redux-firestore'

import {Box,Button,Grid} from '@mui/material';


import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  }
}));

const Login = () => {

    const classes = useStyles();
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        //signInSuccessUrl: 'https://climatesens.com/',
        // We will display Google and Facebook as auth providers.
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                //console.log(authResult)
                //var user = authResult.user;
                //var credential = authResult.credential;
                //var isNewUser = authResult.additionalUserInfo.isNewUser;
                //var providerId = authResult.additionalUserInfo.providerId;
                //var operationType = authResult.operationType;
                window.location.href = "/";
                return false;
            },
            uiShown: function() {
            }
        },
        privacyPolicyUrl: function() {
            window.location.assign('https://climatesens.com/privacy');
          }
    };

    var user = firebase.auth().currentUser;
    //if (user)
    //    return <Redirect to="/" />
    return (
        <main className={classes.root}>
            <Box  className={classes.Main}>
                <h1>Log in</h1>
                <Box >
                    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
                </Box>
                <Box  className={classes.center}>By logging in, you accept <NavLink to="/terms">Terms and Conditions</NavLink> and <NavLink to="/privacy">Privacy Policy</NavLink></Box>
            </Box>
        </main>
    ) 
}
export default Login
