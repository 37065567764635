import React, {Component ,useState} from 'react'
import { connect } from 'react-redux'
import { Link,Navigate  } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import axios from 'axios'

import {Divider ,TextField,Grid,Button,Box} from '@mui/material';


import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {useParams, useLocation ,useNavigate  } from 'react-router-dom'

import { makeStyles } from "@mui/styles"
import mainStyle from "../../assets/mainStyle";
import Page404 from '../Page404';
import Login from '../auth/Login';

const useStyles = makeStyles({
...mainStyle,
DeviceOptions:{
    marginBottom:"20px"
}
})



const AddDevice = ({user,Units,Loaded,Empty}) => {

    const classes = useStyles();
    const navigate = useNavigate()

    const [values, setvalues] = useState({});
    const [redirectUrl, setRedirectUrl] = useState(null);
    let { id } = useParams();
    
    
    const handleChange = (e) => {
        setvalues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit= () =>
    {
        console.log("handleSubmit");
        if(!user)
        {
            alert("Being logged in is required");
            return;
        }

        if(!values.serialNumber  || !values.pin  )
        {
            alert("Serial and Pin are required");
            return;
        }
        
        
        firebase.auth().currentUser.getIdToken().then(function(idToken) {
            axios.post('https://api.climatesens.com/add/existing', {
                serial: values.serialNumber,
                pin: values.pin,
            }
            ,{ "headers":{"Authorization": idToken}})
            .then(function (response) {
                console.log("response");
                console.log(response);

                if(response.data && response.data.success )
                {
                    navigate("/activity/"+values.serialNumber)
                }
            })
            .catch(function (error) {
                console.log("error");
                console.log(error);
            })
            .finally(function () {

            });
        });
    }


    if(!user)
        return(<Login/>)

        
    if(redirectUrl)
        return (<Navigate  to={redirectUrl}/>)


    if(!user)
        return(<Page404/>)


        return (
            <main className={classes.root}>
                <Box className={classes.DeviceOptions}>
                    <h1 class="text-center">Add Existing Device</h1>
                    <p>Add new device using its serial number and pin. Climate Sens devices will have this on them, other devices would have been generated and may have not been attached to device.</p>
                    
                    
                    <TextField margin="normal" fullWidth onChange={handleChange} name="serialNumber" className={classes.TextField} variant="outlined" label="Serial Number" />
                    <TextField margin="normal" fullWidth onChange={handleChange} name="pin" className={classes.TextField} variant="outlined" label="Pin" />
                    <Button onClick={handleSubmit}  className={classes.SendButton} variant="outlined">Submit</Button>
                </Box>
                <Divider/>
                <Box className={classes.DeviceOptions}>
                    <h1 class="text-center">Find your Device</h1>
                    <p>Select the device you would like to add from our device lists.</p>
                    <Button component={NavLink} to="/devices"  className={classes.SendButton} variant="outlined">Select Device</Button>
                </Box>
                <Divider/>
                <Box className={classes.DeviceOptions}>
                    <h1 class="text-center">Configure your own Device</h1>
                    <p>Configer your own device to start logging data with Climate Sens</p>
                    <Button component={NavLink} to="/add/configure"  className={classes.SendButton} variant="outlined">Configure Device</Button>
                </Box>
            </main>
        )
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
    }
}

export default compose(
    connect(mapStateToProps)
)(AddDevice);