import React, {Component ,useState} from 'react'
import { Link } from 'react-router-dom'
import DeviceList from './devices/List'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'
//import { firestore } from 'firebase'
import { NavLink } from 'react-router-dom'
import {  GoogleReCaptchaProvider,  GoogleReCaptcha} from 'react-google-recaptcha-v3';


import axios from 'axios'
import parse from 'html-react-parser';


import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';

import HorizontalAds from "../layout/Ads/HorizontalAds"

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { reduxFirestore, getFirestore } from 'redux-firestore'
import ReactDOM from 'react-dom'


import { makeStyles } from '@mui/styles';
import mainStyle from "../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  },
  loading:{
    textAlign:"center"
  }
}));

var timeoutTimer = null


const Contact = ({user}) => {

    const classes = useStyles();
    const [values, setValues] = useState({});
    const [{Loading,finished,error}, setForm] = useState({Loading:false, finished:false,error:false});
  
    
    //const analytics = getAnalytics();
    //logEvent(analytics,"page_view",{"page_location":window.location.pathname})
    
    //document.title = 'Tinkers Projects - Contact'
  
    if(finished)
        return (
          <main className={classes.root} >
                  <h1>Contact</h1>
                <h3>Thank you</h3>
  
                <Button component={NavLink} to="/" variant="contained" color="primary">
                    Go home
                </Button>
  
            </main>
        )
  
    if(Loading)
        return (
            <main className={classes.root} >
                    <h1>Contact</h1>
                    <Box className={classes.loading}>
                        <CircularProgress  className={classes.CircularProgress}/>
                        <h4>Loading</h4>
                    </Box>
                </main>)
  
  
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
  
    
    const handleVerify=token => {
        setValues({
            ...values,
            token: token
        });
    }
  
    const handleTimeout = () => {
        console.log()
        if(!finished)
        {
          setForm({Loading:false, finished:false, error:true});
          clearInterval(timeoutTimer);
        }
    };
  
    const handleSubmit = (event) => { 
        event.preventDefault();
        console.log("submit")
        timeoutTimer = setTimeout(handleTimeout, 30000)
        setForm({Loading:true, finished:false, error:false});
        var MessageData = {};
  
        MessageData.service = "Climate Sens";
  
        if(values.name)
            MessageData.name = values.name;
  
        if(values.token)
            MessageData.token = values.token;
    
        if(values.email)
            MessageData.email = values.email;
            
        if(values.message)
            MessageData.message = values.message;
  
        axios.post('https://api.spried.com/contact',MessageData).then(Result => {
            console.log("Contact Result")
            console.log(Result)
            clearInterval(timeoutTimer);
            setForm({Loading:false, finished:true, error:false});
            
        }).catch(err => {
            clearInterval(timeoutTimer);
            setForm({Loading:false, finished:false, error:true});
        });  
    };
  
  
  

        return (
            <main className={classes.root} >
                    <h1>Contact</h1>
                    <p>If you need help with Climate Sens or have ideas for climate Sens could do for you, contact Climate Sens.</p>

                    <HorizontalAds/>

                    <GoogleReCaptchaProvider reCaptchaKey="6Ldt0FkdAAAAAJ7wk1tteGK4IxMlTS9CXJhUD3DT">
                        <h2>Contact Form</h2>
                        
                        {error ? <Alert severity="error">Error — Try again later.</Alert> : null}

                        <TextField margin="normal" fullWidth onChange={handleChange} name="name" className={classes.TextField} variant="outlined" label="Name" />
                        <TextField margin="normal" fullWidth onChange={handleChange} name="email" className={classes.TextField} variant="outlined" label="Email" />
                        <TextField margin="normal" fullWidth onChange={handleChange} name="message" className={classes.TextField} variant="outlined" label="Message" multiline rows={4}/>
                        {values && !values.token ? <GoogleReCaptcha onVerify={handleVerify} /> : null}

                        <Button margin="normal" onClick={handleSubmit}  type="submit" className={classes.SendButton} variant="contained">Send</Button>

                    </GoogleReCaptchaProvider>
                    <br/>
                    <br/>

                    <Divider />
                    
<HorizontalAds/>
                    <br/>

                    <h2>Contact Details</h2>
                    <p>Use email below to send an email.</p>
                    <p><a href="mailto:climatesens@gmail.com?subject=Climate Sens Contact">climatesens@gmail.com</a></p>
               
<HorizontalAds/>
            </main>
        )
}



const mapStateToProps = (state) => {

    return {
        user:firebase.auth().currentUser
    }
}

export default compose(
    connect(mapStateToProps)
)(Contact);