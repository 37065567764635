import React, {Children, Component,useState  } from 'react'
import * as Widgets from './Index';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';




import {
    Box,
    Tabs,
    Tab,IconButton,
    CardHeader,
    Select,
    Grid,
    TextField, 
    MenuItem,
    Switch,
  Checkbox,
  Divider,
  FormControlLabel,TextareaAutosize,
  Typography,
  Button
      } from '@mui/material';


import { makeStyles } from "@mui/styles/";
import mainStyle from "../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        //padding: " 0 20px;",
    }
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


const TabBar = ({children,SelecedDetails,handleDetailsChange}) => {
    const [TabValue, setTabValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleGridChange = (event) => {
        //handleDetailsChange(null, "xs", 12);
        //handleDetailsChange(null, "sm", 12);
        handleDetailsChange(null, "md", event.target.value);
        //handleDetailsChange(null, "lg", event.target.value);
        //handleDetailsChange(null, "xl", event.target.value);
    };


    const widgetId = SelecedDetails &&  SelecedDetails.widget ? SelecedDetails.widget : null


    if(Widgets[widgetId] && Widgets[widgetId].Settings && Widgets[widgetId].Settings.options && "grid" in Widgets[widgetId].Settings.options && Widgets[widgetId].Settings.options.grid == false )
    {
        return children
    }


    return (
        <Box>
            <Tabs
                value={TabValue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="Settings" />
                <Tab label="Grid"/>
            </Tabs>

            <TabPanel value={TabValue} index={0}>
                {children}
            </TabPanel>

            <TabPanel value={TabValue} index={1}>
                <p>
                    Select the box's width:
                </p>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={SelecedDetails && SelecedDetails.md ? SelecedDetails.md : 12}
                    name="md"
                    onChange={handleGridChange}
                    fullWidth
                >
                    <MenuItem value={12}>Full Width</MenuItem>
                    <MenuItem value={6}>Half Width</MenuItem>
                    <MenuItem value={4}>Third Width</MenuItem>
                    <MenuItem value={3}>Quater Width</MenuItem>
                    <Divider/>
                    <MenuItem value={8}>2/3</MenuItem>
                    <MenuItem value={9}>3/4</MenuItem>
                </Select>
            </TabPanel>
      </Box>
    )
}



const RenderFormInputs =({handleDetailsArrayRemove,handleDetailsArrayAdd,element,SelecedDetails,handleDetailsChange, handleDetailsArrayChange}) => {

    const handleCheck = (event)=>
    {
        handleDetailsChange(null, event.target.name, event.target.checked);
    }

    
    switch (element.type) {
        case "switch":
            return(<FormControlLabel control={<Switch onChange={handleCheck} name={element.id} checked={SelecedDetails[element.id] ? true : false}/>} label={element.name} />)
        
        case "checkbox":
            return(<FormControlLabel control={<Checkbox  onChange={handleCheck} name={element.id} checked={SelecedDetails[element.id] ? true : false} />} label={element.name} />)
            
        case "array":
            return( <Box>
                        {SelecedDetails[element.id] && SelecedDetails[element.id].map((ArrayDetails,index)=>{
                            return(<Box style={{display: 'flex',margin:"20px 0"}}>
                                <Box style={{ flexGrow: 1 }}>
                            <Inputs DataId={element.id} handleDetailsChange={(event, name=null, value=null)=>handleDetailsArrayChange(event,index,name,value,element.id)} SelecedDetails={ArrayDetails} settings={element.settings}/>
                            </Box>
                            <Box>
                            <IconButton style={{marginTop:"5x"}} color="primary" aria-label="delete" component="span" onClick={(event) => handleDetailsArrayRemove(event,element.id,index)}>
                                <HighlightOffIcon />
                            </IconButton>
                            </Box>
                            </Box>)
                        })}
                        <Button onClick={(event)=>handleDetailsArrayAdd(event,element.id)} >Add</Button>
                    </Box>)
            
        case "html":
            return(<Box>{element.html}</Box>)
    
    
        case "textarea":
            return(<Box>
                <p style={{ marginBottom:5 }}> {element.name} </p>
                <TextareaAutosize
                //aria-label="minimum height"
                minRows={3}
                //placeholder="Minimum 3 rows"
                style={{ width: "100%" }}
                fullWidth
                name={element.id} value={SelecedDetails[element.id] ? SelecedDetails[element.id] : null} onChange={handleDetailsChange} label={element.name} variant="standard" 
              /></Box>)
                
        
        case "number":
        case "date":
        case "text":
        case "color":
            return(<TextField fullWidth type={element.type}  name={element.id} value={SelecedDetails[element.id] ? SelecedDetails[element.id] : null} onChange={handleDetailsChange} label={element.name} variant="standard" />)
            
    }
    return null
  }


const Inputs =({DataId, SelecedDetails, settings, handleDetailsChange}) =>{
    //const widgetId = SelecedDetails &&  SelecedDetails.widget ? SelecedDetails.widget : null

    const handleDetailsArrayChange = (event,index, name=null, value=null, ArrayId=null) => {
        
        var newSelecedDetails = [...SelecedDetails[ArrayId]]

        if(name)
        {
            newSelecedDetails[index] = { ...newSelecedDetails[index],
                [name]:value}
            handleDetailsChange(null, ArrayId,newSelecedDetails);
            return;
        }

        newSelecedDetails[index] = { ...newSelecedDetails[index],
            [event.target.name]:event.target.value}
        
        handleDetailsChange(null, ArrayId,newSelecedDetails)
    };

    const handleDetailsArrayAdd = (event, name=null) => {
        
        if(!SelecedDetails[name])
        {
            handleDetailsChange(null, name,[ {}])

        }
        else
        {
            handleDetailsChange(null, name,[ ... SelecedDetails[name], {}])
        }

    };

    const handleDetailsArrayRemove = (event, name=null, index=null) => {
        
        var newSelecedDetails = [...SelecedDetails[name]]

        newSelecedDetails.splice( index,1);
        console.log(newSelecedDetails)
        handleDetailsChange(null, name,newSelecedDetails)
    };
  


    return( <Grid container spacing={2}>
            

            {/* SelecedDetails.id && Widgets[widgetId] && Widgets[widgetId].Settings && Widgets[widgetId].Settings.options && Widgets[widgetId].Settings.options.settings && Widgets[widgetId].Settings.options.settings.map((element)=>{*/}
            { settings && settings.map((element, index)=>{

                if(!element.type)
                    return null


                return (
                    <Grid item 
                        xs={element.size && element.size.xs ? element.size.xs : 12}
                        sm={element.size && element.size.sm ? element.size.sm : 12}
                        md={element.size && element.size.md ? element.size.md : 12}
                        lg={element.size && element.size.lg ? element.size.lg : 12}
                        xl={element.size && element.size.xl ? element.size.xl : 12}
                    >
                        <RenderFormInputs handleDetailsArrayRemove={handleDetailsArrayRemove} handleDetailsArrayAdd={handleDetailsArrayAdd} element={element} SelecedDetails={SelecedDetails} handleDetailsChange={handleDetailsChange} handleDetailsArrayChange={/*(event,index=null,name=null,value=null)=>*/handleDetailsArrayChange/*(event,index,name,value,element.id)*/}/>
                    </Grid>)

            })}

        </Grid>
    )
}



const View = ({SelecedDetails, handleEditChange,handleDetailsChange}) => {
    const widgetId = SelecedDetails &&  SelecedDetails.widget ? SelecedDetails.widget : null

    if(widgetId == null)
      return <TabBar SelecedDetails={SelecedDetails} handleDetailsChange={handleDetailsChange}>
        <Box style={{padding:"10px 0"}}>
                <TextField  name="name" value={SelecedDetails.name ? SelecedDetails.name : null} onChange={handleDetailsChange} label="Block's Name" variant="standard" />
                </Box>
            </TabBar>

    return <TabBar SelecedDetails={SelecedDetails} handleDetailsChange={handleDetailsChange}>
        <Box style={{padding:"10px 0"}}>
                <TextField  name="name" fullWidth value={SelecedDetails.name ? SelecedDetails.name : null} onChange={handleDetailsChange} label="Block's Name" variant="standard" />
                </Box>
                { SelecedDetails.id && Widgets[widgetId] && Widgets[widgetId].Settings && Widgets[widgetId].Settings.options && Widgets[widgetId].Settings.options.view ?
                    <Box>
                        {Widgets[widgetId].Settings.options.view}
                    </Box> : null
                }

                    <Inputs SelecedDetails={SelecedDetails}  settings={Widgets[widgetId] && Widgets[widgetId].Settings  && Widgets[widgetId].Settings.options && Widgets[widgetId].Settings.options.settings ? Widgets[widgetId].Settings.options.settings : null} handleDetailsChange={handleDetailsChange}/>
            </TabBar>
}

export default View;