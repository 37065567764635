import React, { useEffect , Component,useState  }  from 'react';
import {Select,MenuItem,Grid,Alert,Button,Box,Chip,Card,CardActionArea,CardContent,Typography} from '@mui/material';
import { NavLink } from 'react-router-dom'


import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    
    padding: '30px',
    width:"100%"
  },
  SponsorBox:{
  },
  SponsorDetails:{
    flexGrow:1
  },
  SponsorImage:{
    width:"150px",
    height:"150px",
    objectFit: "scale-down;",
    ['@media (max-width:550px)']: { 
        texta:"block !important;",
        marginTop:"10px"
    }
  },
  sponsorTags:{

  },
  sponsorTag:{
    marginRight:"5px",
    marginTop:"10px",
  },
  SponsorActions:{
    display:"flex",
    marginTop:"10px"
  },
  SponsorBody:{
    display:"flex",
    ['@media (max-width:550px)']: { 
        display:"block !important;"
    }
  },
  sponsorImageBox:{
    ['@media (max-width:550px)']: { 
        width:"100%",
        textAlign: "center;"
    }
  },
  Sponsor:{
    borderBottom:"1px solid #444;",
    padding:"20px 0"
  },
  sponsorTitle:{
      color:"#333",
      fontSize:"24px",
      textDecoration:"none",
      "&:hover":{
        textDecoration:"underline",
      }
  },
  CostsBox:{
    display:"flex"
  },
  Price:{
    padding:"5px 15px"
  },
  sponsorDescription:{
      marginTop:"10px"
  }
}));


const ListSponsors = ({Sponsors}) => {

    const classes = useStyles();
    
  return(<Box>
      { Sponsors && Sponsors.map((sponsor,index) => {
                return ( <Box className={classes.Sponsor}>
                            <Box className={classes.SponsorBody}>
                                <Box className={classes.SponsorDetails}>
                                    <Box  className={classes.sponsorTitle}  target="_blank" component='a'  href={sponsor.url}>{sponsor.name}</Box>
                                    <Box className={classes.sponsorTags}> 
                                        { sponsor.tags && sponsor.tags.map(tag => { return (<Chip  className={classes.sponsorTag} label={tag} />) })}
                                    </Box>
                                    <Box className={classes.sponsorDescription}>{sponsor.description}</Box>
                                </Box>
                                
                                    {sponsor.image != null && sponsor.image != "" ? <Box className={classes.sponsorImageBox}><img src={sponsor.image}  /*onerror="this.onerror=null; this.src='/images/no-image-available.png'"*/  className={classes.SponsorImage}/></Box> : null}
                                
                            </Box> 

                            <Box className={classes.SponsorActions}>
                                <Button component-="a" target="_blank" href={sponsor.url} variant="outlined">See more</Button>
                                <Box className={classes.spacer}/>
                          </Box>
                        </Box>
                )
            })}


  </Box>)

}


export default  (ListSponsors)