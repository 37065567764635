import React, {Component,useState} from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import { AppBar,Toolbar,Typography,Box,IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { firestoreConnect, isEmpty, isLoaded } from  'react-redux-firebase'

import { Provider,useSelector } from 'react-redux'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import { compose } from 'redux'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { Collapse,Button } from '@mui/material';


import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ... mainStyle,
  root:{
    backgroundColor:" #fff !important;",
    position: "fixed;",
    top: 0,
    right: 0,
    left: 0,
    zIndex: "1030;",
    boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)!important;",
    minHeight:" 50px !important;",
    ['@media (max-width:750px)']: { 
        position: "relative !important;",
    }
  },
  Toolbar:{
    backgroundColor:" #fff !important;",
    paddingLeft:"16px!important",
    paddingRight:"16px!important",
    minHeight:" 50px !important;;"
  },
  heading:{
    paddingTop:" 0.75rem;",
    paddingBottom: "0.75rem;",
    fontSize: "16px",
    fontWeight: "600;",
    color: "#333;",
    letterSpacing: "5px;",
    textDecoration:"none"
    //boxShadow: "inset -1px 0 0 rgb(0 0 0 / 25%);",
  },
  /*MenuRoot:{
      display:"none !important;"
  },*/
  MenuRootActive:{
      display:"none !important;",
      padding: "0",
      boxShadow: "inset -1px 0 0 rgb(0 0 0 / 10%)",
      backgroundColor: "#f8f9fa;",
      ['@media (max-width:750px)']: { 
        display:"block !important;"
    }
  },
  MenuButton:{
    color:"#757575",
    marginLeft:"20px",
    display:"none !important;",
    ['@media (max-width:750px)']: { 
        display:"block !important;"
    }
  }
}));

const  Header = ({user,UnreadNotifications}) => {

const classes = useStyles();
const [DisplayMenu, SetMenu] = useState(false);

const handleMenu = ()=>{
    SetMenu(!DisplayMenu)
}

const handleMenuClose = ()=>{
    SetMenu(false)
}

    
  const auth = useSelector(state => state.firebase.auth)


        return (
            <>
      <AppBar className={classes.root}>
        <Toolbar className={classes.Toolbar}>
            <Box
                component={NavLink}
                to="/"
                className={classes.heading}
            >
                Climate Sens
            </Box>
            <Box
                className={classes.MenuButton}
                onClick={handleMenu}
            >
                <MenuIcon />
            </Box>


            <Box className={classes.spacer}/>
            {!isLoaded(auth) ? null :
                        user == null ? 
                        <Button variant="outlined" component={NavLink} to="/login" >Login</Button>

                      :
                        
                        <>
                            <IconButton
                                size="large"
                                component={NavLink}
                                to="/notifications"
                            >                                            <Badge color="secondary" variant="dot" invisible={!isEmpty(UnreadNotifications) && isLoaded(UnreadNotifications) ? false : true}>

                                <NotificationsIcon />
                                </Badge>
                            </IconButton>

                            <IconButton
                                size="large"
                                component={NavLink}
                                to="/profile"
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </>
                   }
            </Toolbar>
            </AppBar>






<Collapse in={DisplayMenu}>
<Box className={classes.MenuRootActive}>
        
        <List>
        {user ? <>
        
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose} component={NavLink} to="/">
                    <ListItemText primary="My Dashboard" />
                </ListItemButton>
            </ListItem>
            {/*<ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/account/devices">
                    <ListItemText primary="My Devices" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/account/activity">
                    <ListItemText primary="My Activity" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/account/reports">
                    <ListItemText primary="My Reports" />
                </ListItemButton>
            </ListItem>*/}
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/add">
                    <ListItemText primary="Add Device" />
                </ListItemButton>
            </ListItem>
      <Divider />
        </> : null

        }



            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to={user?"/home":"/"}>
                    <ListItemText primary="Home" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/devices">
                    <ListItemText primary="Devices" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/activity">
                    <ListItemText primary="Activity" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/products">
                    <ListItemText primary="Products" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/reports">
                    <ListItemText primary="Reports" />
                </ListItemButton>
            </ListItem>
            
      <Divider />


      <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/about">
                    <ListItemText primary="About" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/contact">
                    <ListItemText primary="Contact" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/documents">
                    <ListItemText primary="Documents" />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose} component="a" target="_blank" href="https://feedback.spried.com/service/climate-sens">
                    <ListItemText primary="Feedback/bugs" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/sponsors">
                    <ListItemText primary="Sponsors" />
                </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuClose}  component={NavLink} to="/terms">
                    <ListItemText primary="Terms and Conditions" />
                </ListItemButton>
            </ListItem>

        </List>
            
        {user == null ?
                <Box  disablePadding sx={{paddingBottom:"10px"}} className={classes.center}>
                    <Button  onClick={handleMenuClose}   variant="outlined" component={NavLink} to="/login" >Login</Button>
                </Box>
             : null}



  </Box>
  </Collapse>
  </>
        );
}

const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
        UnreadNotifications: state.firestore.ordered.UnreadNotifications,
    }
}

export default compose(    connect(mapStateToProps),    firestoreConnect(props =>{
    
    if(firebase.auth().currentUser)
    {
        var UID = firebase.auth().currentUser.uid
        return[
            { collection: 'Users', storeAs: 'UnreadNotifications', doc:UID, subcollections:[{collection: 'Notifications',where:[["read","==",false]],limit:1}]},
        ]
    }
    return[]

})) (Header)