import React from 'react';
import { compose } from 'redux'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Provider,useSelector } from 'react-redux'
import { reactReduxFirebase, getFirebase, isLoaded } from 'react-redux-firebase'

import { AppBar,Toolbar,Typography,Box,IconButton } from '@mui/material';


import Header from './layout/Structure/Header'
import Sidebar from './layout/Structure/Sidebar'
import Footer from './layout/Structure/Footer'
import Loader from './layout/Loader'

import Home from './views/Home'
import Login from './views/auth/Login'
import Logout from './views/auth/Logout'
import UnderConstrution from './views/UnderConstrution'
import Page404 from './views/Page404'

import Privacy from './views/Privacy'
import Terms from './views/Terms'
import About from './views/About'
import ContactForm from './views/contact'

import SponsorsList from './views/Sponsors/List'
import SponsorsApply from './views/Sponsors/Apply'

//import DocumentsList from './views/Documents/List'
import Document from './views/Documents/Document'


import ActivityList from './views/activity/List'
import ActivityDetails from './views/activity/Details'
import ActivityEdit from './views/activity/Edit'
//import ActivityAnalyze from './views/activity/ActivityAnalyze'

import ReportsList from './views/Reports/List'


import ProductsList from './views/products/List'

import DeviceDetails from './views/devices/Details'
import DevicesList from './views/devices/List'
import DeviceEdit from './views/devices/Edit'

import Add from './views/Add/Add'
import AddConfigure from './views/Add/Configure'
import AddNewDevice from './views/Add/NewDevice'

import Dashboard from './views/account/Dashboard'
import Notifications from './views/account/Notifications'
import Profile from './views/account/Profile'
//import AddDevice from './views/account/devices/DeviceAdd'



import { makeStyles } from '@mui/styles';
import mainStyle from "./assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ... mainStyle,
  root:{

  },
  main:{
    marginLeft:"200px",
    marginTop:"50px",
    ['@media (max-width:750px)']: { 
      marginLeft:"0px",
      marginTop:"0px",
    },
    display: "flex;"
  }
}));



function AuthIsLoaded({ children }) {

  const auth = useSelector(state => state.firebase.auth)

  if (!isLoaded(auth)) 
    return (<Box sx={{marginTop:"100px"}}><Loader/></Box>);

  return children
}


function App({user}) { 

  const classes = useStyles();

  return (
    <BrowserRouter>
      <Header />
        <AuthIsLoaded>

            <Sidebar />
            
              <Box className={classes.main}>
              <Routes>
                <Route exact path='/' element={user ? <Dashboard/> : <Home/> } /> 
                <Route exact path='/home' element={<Home/>} />

                <Route exact path='/terms' element={<Terms/>} />
                <Route exact path='/privacy' element={<Privacy/>} />
                <Route exact path='/about' element={<About/>} />
                <Route exact path='/contact' element={<ContactForm/>} /> 
            
                <Route exact path='/sponsors' element={<SponsorsList/>} />
                <Route exact path='/sponsors/apply' element={<SponsorsApply/>} />
                
                <Route exact path='/logout' element={<Logout/>}/>
                <Route path='/login' element={<Login/>} /> 


                <Route exact path='/notifications' element={<Notifications/>/*Notifications*/} /> 
                <Route exact path='/profile' element={<Profile/>/*Notifications*/} /> 
                
                <Route path='/add' element={<Add/>} />
                <Route path='/add/configure' element={<AddConfigure/>} />
                <Route path='/add/:deviceId' element={<AddNewDevice/>} />

                <Route exact path='/devices' element={<DevicesList/>} /> 
                <Route path='/devices/:cat' element={<DevicesList/>} /> 
                {/*<Route path='/device/add' element={<DeviceAdd/>} />*/}  
                <Route path='/device/:id' element={<DeviceDetails/>} />  
                <Route path='/device/:id/edit' element={<DeviceEdit/>} />  


                <Route exact path='/activity' element={<ActivityList/>} /> 
                <Route path='/activity/:id' element={<ActivityDetails/>} /> 
                <Route path='/activity/:id/edit' element={<ActivityEdit/>} /> 
                {/*<Route exact path='/activity/map' element={ActivityMap} /> 
                {/*<Route exact path='/activity/analyze' element={ActivityAnalyze} /> */}


                <Route exact path='/documents' element={<Document/>} /> 
                <Route exact path='/documents/*' element={<Document/>} /> 


                <Route exact path='/reports' element={<ReportsList/>/*Reports*/} /> 
                <Route exact path='/report/:id' element={<UnderConstrution/>/*Reports*/} /> 
                

                <Route exact path='/products' element={<ProductsList/>} /> 
                {/*<Route exact path='/product/:id' element={<UnderConstrution/>/*Products* /} /> 
              
{/*}
                <Route exact path='/account/devices' element={UnderConstrution/*Dashboard* /} /> 
                <Route exact path='/account/device/add' element={AddDevice} /> 
                <Route exact path='/account/device/:id' element={Devices} /> 

                <Route exact path='/account/activity' element={ActivityOverview}/> 
                <Route exact path='/account/activity/:id' element={ActivityDevice} /> 
                
                <Route exact path='/account/reports' element={UnderConstrution/*Dashboard* /} /> 
                <Route exact path='/account/reports/:id' element={UnderConstrution/*Dashboard* /} /> 
                <Route exact path='/account/settings' element={UnderConstrution/*Settings* /} /> */}
                  
                
                {/* 404 */}
                
                <Route path="*"element={<Page404/>}  status={404}/>

              </Routes>
          </Box>
            </AuthIsLoaded>
        {/*<Footer />*/}
      </BrowserRouter>
  );
}

const mapStateToProps = (state) => {

    return {
        user:firebase.auth().currentUser
    }
}

export default compose(
    connect(mapStateToProps),
)(App);
