import React, { useEffect , Component,useState  }  from 'react';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { firestoreConnect,isLoaded,isEmpty  } from  'react-redux-firebase'

import firebase from 'firebase/compat/app';
import { useFirebaseConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import {Select,MenuItem,Grid,Alert,Button,Box,Chip,Card,CardActionArea,CardContent,Typography} from '@mui/material';

import Loader from "../../layout/Loader"
import Page404 from "../Page404"

import HorizontalAds from "../../layout/Ads/HorizontalAds"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    
    padding: '30px',
    width:"100%"
  },
  DeviceBox:{
  },
  DeviceDetails:{
    flexGrow:1
  },
  DeviceImage:{
    width:"150px",
    height:"150px"
  },
  deviceTags:{

  },
  deviceTag:{
    marginRight:"5px",
    marginTop:"10px",
  },
  DeviceActions:{
    display:"flex",
  },
  DeviceBody:{
    display:"flex",
  },
  Device:{
    borderBottom:"1px solid #444;",
    padding:"20px 0"
  },
  deviceTitle:{
      color:"#333",
      fontSize:"24px",
      textDecoration:"none",
      "&:hover":{
        textDecoration:"underline",
      }
  },
  MoreData:{
    textAlign:"center",
    padding: ' 30px 0 ',
  }
}));


const Devicelist = ({Devices,Empty,Loaded}) => {

    const classes = useStyles();
    //let params = useParams();
/*
  useFirestoreConnect([
    { collection: 'DeviceTypes', storeAs: 'Devices', orderBy:["name",'asc']/*,where:whereQuery* /}, //add public
  ])

  if(!Loaded)
  {
    return (<Loader/> )
  }

  if(Empty)
  {
    return (<Page404/>)
  }*/



  return(<main className={classes.root}>
      <Box className={classes.tiltBlock}>
        <h1>Reports</h1>
        <p>From the data that Climate Sens has gathered, we have generated reports.  </p>
        {/* ADD SELECT AND SETTINGS */}
      </Box>

      <HorizontalAds/>
        <Box className={classes.MoreData}>
            <h2>Need more data.</h2>
            <p> Climate Sens Needs more data before reports can be made. You can help be connection a devices</p>
            
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                    <Button fullWidth  variant="outlined" component={NavLink}  to="/devices">Find a device</Button>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Button fullWidth variant="outlined" component={NavLink}  to="/devices/add">add a device</Button>
                </Grid>
                <Grid item  xs={12} sm={12} md={4}>
                    <Button fullWidth variant="outlined" component={NavLink} to="/documents">Read the documents</Button>
                </Grid>
            </Grid>
        </Box> 

        <HorizontalAds/>





  </main>)


}




const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
        Reports: state.firestore.ordered.Reports,
        Loaded:isLoaded(state.firestore.ordered.Reports) ,
        Empty:isEmpty(state.firestore.ordered.Reports)
    }
  }


export default  compose(
    connect(mapStateToProps),
  )(Devicelist)