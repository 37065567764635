import AdSense from 'react-adsense';
import React from 'react'

import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';

import mainStyle from "../../assets/mainStyle";
const useStyles = makeStyles({
...mainStyle,
AdBox: {
    width:"100%",
    minHeight:"90px",
    background:"#eef",
    marginTop:"10px",
    marginBottom:"10px",
}
});

const HorizontalAds = (props) => {
    const classes = useStyles();
    const [update, setupade] = React.useState(false);

    window.addEventListener('resize', function() {
        setupade(!update)
    });
    
    return (<Box className={classes.AdBox}>
                {/*<AdSense.Google
                    client='ca-pub-3720788323192251'
                    slot='1009908347'
                    style={{ display: 'block' }}
                    format='auto'
                    responsive='true'
                    //layoutKey='-gw-1+2a-9x+5c'
                />*/}
        </Box>)
}

export default (HorizontalAds);