const mainStyle = {
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  },
    white:{
      backgroundColor:"#fff",
    },
    black:{
      color:"#444;"
    },
    Unknown:{
      color:"#aaa;"
    },
    spacer:{
      flexGrow:1
    },
    center:{
      textAlign:"center"
    }
  };
  export default mainStyle;
  