import React, {Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect, isEmpty, isLoaded } from  'react-redux-firebase'
import { compose } from 'redux'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { useFirestoreConnect } from 'react-redux-firebase'
  

//import apiAccess from "../store/api/apiAccess"
import Page404 from "../Page404"


import {Container,Paper,Divider} from '@mui/material';
import Box from '@mui/material/Box';


import Loader from '../../layout/Loader'


//import styles from "../../assets/views/IssuesDetailsStyle";
import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles({
    ...mainStyle,
    note:{
        color:"#444",
        textDecoration:"none"
    },
    noteTitle:{
        fontSize:"20px"
    },
    noteBlockUnread:{
        background:"red"
    },
    noteDate:{

        fontSize:"16px"
    },
    noteRead:{

        fontSize:"16px",
        color:"#bbb"
    },
    noteUnRead:{

        fontSize:"16px"
    },

});
    
 
const View = ({Notifications,user}) => {
        
    const classes = useStyles();
     
    const timeAgo = new TimeAgo('en-US')
    timeAgo.format(new Date())


    useFirestoreConnect(user ? [
         { collection: 'Users', storeAs: 'Notifications', doc: user.uid, subcollections:[{collection: 'Notifications',orderBy:"created",limit:3}]} ,
    ]:[])

    if(!user)
        return <Page404/>
    
    document.title = "Fix My Stuff - Notifications"


        
    if(!isLoaded(Notifications))
    {
        return (
            <Container>
                <h1>Notifications</h1>
                <Loader/>
            </Container>)
    }


    if(isEmpty(Notifications))
    {
        return (
            <Container>
                <h1>Notifications</h1>
                <h3>There are no notifications</h3>
            </Container>)
    }


    return (
    <Container>
        <h1>Notifications</h1>

        <Divider/>

        {Notifications && Notifications.map(( item,index)=>{
            const Date  = item.created ? timeAgo.format(item.created.seconds*1000) : null;;

            return(
                <Box className={classes.note} component={item.link ? Link : null} to={item.link ? item.link : null}>
                    <Box className={classes.noteTitle}>{item.note}</Box>
                    <Box className={classes.noteDate}>{Date}</Box>
                    {item.read ? <Box className={classes.noteRead}>Read</Box> : <Box className={classes.noteUnRead}>Unread</Box> }

                    <Divider/>
                </Box>
            )
        })}

                    
<br/>
                    
                    <br/>
    </Container>)
}

  const mapStateToProps = (state) => {
    return {
        user:firebase.auth().currentUser,
        Notifications: state.firestore.ordered.Notifications,
    }
}


export default compose(    connect(mapStateToProps),    firestoreConnect(props =>{return[]})) (View);