import React, { useState  }  from 'react';
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import firebase from 'firebase/compat/app';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import {Select,MenuItem,Divider,Box} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


import Loader from "../../layout/Loader"
import ListDevices from "../../layout/Device/ListDevices"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    
    padding: '30px',
    width:"100%"
  }
}));


const Devicelist = ({Devices,Empty,Loaded,user}) => {

    const classes = useStyles();
    const [DeviceCategory, setDeviceCategory] = useState("All");
    //let params = useParams();

    var whereQuery = null
    var Query = { collection: 'DeviceTypes', storeAs: 'Devices', orderBy:["name",'asc']} //add public

    switch (DeviceCategory) {
      case "Mine":
        if(user)
          whereQuery=[["user",'array-contains',user.uid]]
        break;
      case "CS":
      case "Commercial":
      case "DIY":
        whereQuery=[["category",'==',DeviceCategory]]
        break;
      case "Other":
        break;
    
      default:
        break;
    }


    if(whereQuery)
      Query.where = whereQuery

  useFirestoreConnect([Query])

  const handleCategoryChange=(event)=>{
    setDeviceCategory(event.target.value)
  }


  if(!Loaded)
  {
    return (<Loader/> )
  }




  return(<main className={classes.root}>
      <Box className={classes.tiltBlock}>
        <h1>Devices</h1>
        <p>These are all of the devices that have been used with Climate Sens.</p>
        <FormControl fullWidth>
          <InputLabel id="Device-Category-label">Device Category</InputLabel>
          <Select
            labelId="Device-Category-label"
            id="Device-Category"
            value={DeviceCategory}
            label="Category"
            onChange={handleCategoryChange}
          >
          <MenuItem value="All">All</MenuItem>
          <Divider/>
            <MenuItem value="Mine">My Devices</MenuItem>
            <Divider/>
            <MenuItem value="CS">Climate Sens Devices</MenuItem>
            <MenuItem value="Commercial">Commercial Devices</MenuItem>
            <MenuItem value="DIY">DIY Devices</MenuItem>
            <MenuItem value="Other">Other Devices</MenuItem>
          </Select>
        </FormControl>
      </Box>



{Empty ? <Box sx={{marginTop:"30px",textAlign:"center"}}>There are no Devices.</Box> : <ListDevices Devices={Devices}/>}




  </main>)

}




const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
        Devices: state.firestore.ordered.Devices,
        Loaded:isLoaded(state.firestore.ordered.Devices) ,
        Empty:isEmpty(state.firestore.ordered.Devices)
    }
  }


export default  compose(
    connect(mapStateToProps),
  )(Devicelist)