import React, { useEffect , Component,useState  }  from 'react';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { firestoreConnect,isLoaded,isEmpty  } from  'react-redux-firebase'

import firebase from 'firebase/compat/app';
import { useFirebaseConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import {Select,MenuItem,Grid,Alert,Button,Box,Chip,Card,CardActionArea,CardContent,Typography} from '@mui/material';

import Loader from "../../layout/Loader"
import Page404 from "../Page404"
import ListSponsors from "../../layout/Sponsors/ListSponsors"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    
    padding: '30px',
    width:"100%"
  },
  SponsorBox:{
  },
  SponsorDetails:{
    flexGrow:1
  },
  SponsorImage:{
    width:"150px",
    height:"150px",
    ['@media (max-width:550px)']: { 
        texta:"block !important;",
        marginTop:"10px"
    }
  },
  sponsorTags:{

  },
  sponsorTag:{
    marginRight:"5px",
    marginTop:"10px",
  },
  SponsorActions:{
    display:"flex",
    marginTop:"10px"
  },
  SponsorBody:{
    display:"flex",
    ['@media (max-width:550px)']: { 
        display:"block !important;"
    }
  },
  sponsorImageBox:{
    ['@media (max-width:550px)']: { 
        width:"100%",
        textAlign: "center;"
    }
  },
  Sponsor:{
    borderBottom:"1px solid #444;",
    padding:"20px 0"
  },
  sponsorTitle:{
      color:"#333",
      fontSize:"24px",
      textDecoration:"none",
      "&:hover":{
        textDecoration:"underline",
      }
  },
  CostsBox:{
    display:"flex"
  },
  Price:{
    padding:"5px 15px"
  },
  sponsorDescription:{
      marginTop:"10px"
  }
}));


const SponsorsList = ({Sponsors,Empty,Loaded}) => {

    const classes = useStyles();

  if(!Loaded)
  {
    return (<Loader/> )
  }

  return(<main className={classes.root}>
      <Box className={classes.tiltBlock}>
        <h1>Sponsors</h1>
        <p>These are the sponsors that help to run and supply devices to gather data</p>

        <Button  variant="outlined" component={NavLink} to="/sponsors/apply">Become a Sponsors</Button>

        <br/>
        
      </Box>
      <ListSponsors Sponsors={Sponsors}/>


      <br/>
            <br/>
            <Button fullWidth variant="outlined" component={NavLink} to="/sponsors/apply">Become a Sponsors</Button>


  </main>)

}




const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
        Sponsors: state.firestore.ordered.Sponsors,
        Loaded:isLoaded(state.firestore.ordered.Sponsors) ,
        Empty:isEmpty(state.firestore.ordered.Sponsors)
    }
  }


export default  compose(
    connect(mapStateToProps),
    firestoreConnect(props =>{
      
      return [
        { collection: 'Sponsors', storeAs: 'Sponsors'}
      ]
  })
  )(SponsorsList)