import React from 'react';
import { isLoaded,isEmpty  } from  'react-redux-firebase'

import firebase from 'firebase/compat/app';
import { connect } from 'react-redux'
import { compose } from 'redux'
import { useFirestoreConnect } from 'react-redux-firebase'

import {Box} from '@mui/material';

import Loader from "../../layout/Loader"
import ListDevices from "../../layout/Device/ListDevices"
import Page404 from "../Page404"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    
    padding: '30px',
    width:"100%"
  }
}));


const Productlist = ({Devices,Empty,Loaded}) => {

    const classes = useStyles();
    //let params = useParams();

  useFirestoreConnect([
    { collection: 'DeviceTypes', storeAs: 'ProductDevices', orderBy:["name",'asc'],where:[["purchasable","==",true]]}, //add public
  ])

console.log(Devices)

  if(!Loaded)
  {
    return (<Loader/> )
  }

  if(Empty)
  {
    return (<Page404/>)
  }



  return(<main className={classes.root}>
      <Box className={classes.tiltBlock}>
        <h1>Devices</h1>
        <p>These are all of the devices that have been used with Climate Sens.</p>
        {/* ADD SELECT AND SETTINGS */}
      </Box>

<ListDevices Devices={Devices}/>




  </main>)

/*
        var Side = false;
        return (<div class="mt-3">
                    { devices && devices.map(device => {
                        
                        if(!altinate || Side)
                        {
                            return (
                                <div class="DeviceItem border-top row py-4">
                                    <div class="col-md-9 device-item-detials">
                                    <h2><NavLink class="black" to={"/device/" + device.id}>{device.name}</NavLink></h2>
                                        <Devicetags deviceTags={device.tags}/>
                                        <p>{device.category}</p>
                                        <p>{device.description}</p>
                                        <NavLink  to={"/device/" + device.id} class="btn btn-outline-dark py-1 mr-2">See more about this </NavLink>
                                        {device.url == null || device.url == "" ? null : <a target="_blank" href={device.url} class="btn btn-outline-dark py-1">Buy</a>}
                                    </div>
                                    <div class="col-md-3">
                                        {device.image == null || device.image == "" ?
                                            <img src="/images/no-image-available.png" class="device-item-image"/>
                                            :
                                            <img src={device.image}  onerror="this.onerror=null; this.src='/images/no-image-available.png'"  class="device-item-image"/>
                                        }
                                    </div>
                                </div>
                            )
                        }
                        else
                        {
                            return (
                                <NavLink  to={"/device/" + device.id} className class="DeviceItem altinate border-top row py-4">
                                    <div class="col-md-3">
                                        {device.image == null || device.image == "" ?
                                            <img src="/images/no-image-available.png" class="device-item-image"/>
                                            :
                                            <img src={device.image} class="device-item-image"/>
                                        }
                                    </div>
                                    <div class="col-md-9 device-item-detials">
                                        <h2>{device.name}</h2>
                                        <div class="btn btn-outline-dark py-1">See more about this </div>
                                    </div>
                                </NavLink>
                            )
                        }
                        Side = Side*-1;
                    })}
                </div>
        )*/

}




const mapStateToProps = (state, ownProps) => {
    return {
        user:firebase.auth().currentUser,
        Devices: state.firestore.ordered.ProductDevices,
        Loaded:isLoaded(state.firestore.ordered.ProductDevices) ,
        Empty:isEmpty(state.firestore.ordered.ProductDevices)
    }
  }


export default  compose(
    connect(mapStateToProps),
    /*firestoreConnect(props =>{
      
      const pathname =  window.location.pathname
      return [
        { collection: 'Pages', storeAs: 'Page', where:[["path",'==',pathname]]}
      ]
  })*/
  )(Productlist)