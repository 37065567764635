import { NavLink } from 'react-router-dom'
import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';

import {Container,Button,Grid,Box,Alert} from '@mui/material';

import HorizontalAds from "../../layout/Ads/HorizontalAds"

import axios from 'axios'
import parse from 'html-react-parser';

import Loader from "../../layout/Loader"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  }
}));

var timeoutTimer = null

const Docs = () => {
    
    const classes = useStyles();
    const [ locationKeys, setLocationKeys ] = useState([])
    const [documentsList, setdocumentsList] = useState([]);
    const [{Loading, finished, error}, setState] = useState({});
    const [DocumentText, setDocumentText] = useState(null);
    const [Document, setDocument] = useState({});


     useEffect(() => {
      window.onpopstate = function(event) {
        setState({})
        setDocumentText()
        setDocument({})
      }
    })

    
    
    const handleTimeout = () => {
      if(finished)
      {
        setState({error:"Timeout"});
        clearInterval(timeoutTimer);
      }
  };


    if(!Loading && !error && !finished)
    {
      setState({Loading:true})
      setDocumentText()
      setDocument({})
      axios.post('https://api.spried.com/documents/climateSens').then(Result => {
          

          clearInterval(timeoutTimer);
          if(Result.data && Result.data.documents)
          {
            setdocumentsList(Result.data.documents)
            
            const pathname = window.location.pathname.replace("/documents","")
            axios.get('https://api.spried.com/documents/climateSens/page'+pathname).then(PageResult => {
              
              setDocument(PageResult.data)
              if(!PageResult.data || !PageResult.data.url)
              {
                setState({finished:true});
                return
              }


              axios.get(PageResult.data.url).then(HtmlResult => {
                //HtmlResult
                
          console.log("HtmlResult.data")
          console.log(HtmlResult.data)

                if(HtmlResult.data)
                  setDocumentText(HtmlResult.data)
                setState({finished:true});
              }).catch(function (error) {
                console.log(error)
                setState({error:"HTML Problem"});
              })
            }).catch(function (error) {
              console.log(error)
              setState({error:"Page Problem"});
            })
          }
          else
          {
            setState({ error:"Could not get documents"});
          }

      }).catch(err => {
          clearInterval(timeoutTimer);
          setState({ error:"Server problems"});
      });  
    }

    
  if(Loading)
  {
    return (<Loader/> )
  }
  
  var pathname = window.location.pathname.replace("/documents","")
  if(pathname.charAt(pathname.length - 1) == "/")
    pathname = pathname.slice(0, -1)
    const pathNameArray = pathname.split("/");
    const pathNameLength = pathNameArray.length
    const pathNameString = pathNameArray.slice(0, pathNameLength).join("/")
        return (
            <main className={classes.root} >
                  
                  
                  <h1>{Document && Document.name ? Document.name : "Climate Sens Documents"}</h1>


                  
                  <HorizontalAds/>
                  
                  {DocumentText ? parse(DocumentText) :null}

                    
                    {documentsList && documentsList.map((item)=>{
                        if(!item.path)
                          return null

                        var pathnameNew = item.path.replace("/documents","")
                        if(pathnameNew != "/" && pathnameNew.charAt(pathnameNew.length - 1) == "/")
                        pathnameNew += pathnameNew.slice(0, -1)
                        if(pathnameNew == "/")// || pathname.charAt(pathname.length - 1) != "/")
                        pathnameNew = ""
                        const itemPathNameArray = pathnameNew.split("/");
                        

                      if(pathnameNew == pathname || itemPathNameArray.length > pathNameArray.length+1 || itemPathNameArray.slice(0, pathNameLength).join("/") != pathNameString)
                        return null

                      return(<Box>
                          <hr/>
                          <h2><NavLink onClick={()=>setState({})} to={"/documents"+ item.path} className={classes.black}>{item.name}</NavLink></h2>
                          <p>Using Devices with Climate Sens.</p>
                          <Button  variant="outlined" component={NavLink}  to={"/documents"+ item.path}>See document <i class="fas fa-chevron-right"></i></Button>
                        </Box>)

                    })}

<HorizontalAds/>
            
                    </main>
        )
    
}
export default Docs
