import React, {Component ,useState} from 'react'
import { Link, Redirect } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';
import { NavLink } from 'react-router-dom'
import en from 'javascript-time-ago/locale/en'

import { isLoaded,isEmpty  } from  'react-redux-firebase'

import { reduxFirestore, getFirestore } from 'redux-firestore'
import { useFirestoreConnect } from 'react-redux-firebase'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'



import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Grid,
    TextField, 
    MenuItem,
    Menu,
  Toolbar,
  FormControlLabel,
  Typography
      } from '@mui/material';

      import * as Widgets from '../../Widgets/Index';
      import WidgetsSettings from '../../Widgets/WidgetsSettings';


import MoreVertIcon from '@mui/icons-material/MoreVert';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';


import { styled } from '@mui/material/styles';


import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle";

const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        width:"100%"
    },
    HeaderBox:{
        padding: " 0 20px;",
    },
    ToolBar:{
        padding:0
    },
    MenuItem:{
      color:"#8f9297",
      fontWeight: "700;",
      padding:0,
      margin:0,
      marginRight:"10px",
      textAlign:"left",

      "&:hover":{
        background:"none",
        color:"#555"
      }
    },
    MenuItemSelected:{
      color:"#000"
    },
    DisplayContent:{
      marginTop:"20px",
      padding: "0 20px;"
    },
    CardRoot:{
        boxShadow:"1px 3px 2px -2px rgb(0 0 0 / 20%), 1px 2px 2px 1px rgb(0 0 0 / 14%), 1px 2px 4px 1px rgb(0 0 0 / 12%);",
        border:"1px #eee solid"
    },
    AddBox:{
      marginTop:"20px",
      marginBottom:"60px",
      textAlign:"center"
    },
    AddButton:{
      padding:"10px 20px"
    }
});


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



var MaxwidgetOrder = 0;


const View = (props) => {
    const classes = useStyles();
    const {user,UserDetails/*,DeviceData*/,DashboardDisplay } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showAddDialog, setshowAddDialog] = React.useState(false);
    const [showEditDialog, setShowEditDialog] = React.useState(false);
    const [expanded, setExpanded] = React.useState(null);
    const [SelecedId, setSelecedId] = React.useState(null);
    const [SelecedDetails, setSelecedDetails] = React.useState(null);
    const [update, setUpdate] = React.useState(false);
    const [LimitCount, setLimitCount] = React.useState(20);



    MaxwidgetOrder = 0;
    DashboardDisplay && DashboardDisplay.forEach(element => {
      if(element.widgetOrder > MaxwidgetOrder)
        MaxwidgetOrder = element.widgetOrder
    });
    


    const handleDetailsChange = (event, name=null, value=null) => {
      if(name)
      {
        setSelecedDetails({
          ...SelecedDetails,
          [name]: value
        });
        return
      }

      setSelecedDetails({
        ...SelecedDetails,
        [event.target.name]: event.target.value
      });
    };



    const handleMenuClick = (event,WidgetId=null) => {
      //console.log("SelecedId")
      //console.log(SelecedId)
      setSelecedId(WidgetId)
      setAnchorEl(event.currentTarget);
    };



    const handleAddButton = ()  => {
      setshowAddDialog(true)
    };



    const handleClose = () => {
      setAnchorEl(null);
      setshowAddDialog(false)
      //setSelecedId(null)
      setShowEditDialog(false)
    };



    const handleDisplayAdd = (WidgetId) => {
      
      MaxwidgetOrder++;

      console.log(WidgetId)
      getFirestore().collection("Users").doc(user.uid).collection("Dashboard").add({
        widget:WidgetId,
        widgetOrder:MaxwidgetOrder
      })
      .then((NewId) => {
        console.log(NewId.id)
          console.log("Document successfully written!");
          //setSelecedDetails(NewId.id);
          setSelecedId(NewId.id)
          setSelecedDetails({
            widget:WidgetId,
            widgetOrder:MaxwidgetOrder
          });
          setShowEditDialog(true);
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });

      handleClose();
    };
    


    const handleDisplayRemove = () => {

        getFirestore().collection("Users").doc(user.uid).collection("Dashboard").doc(SelecedId).delete()
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });

      handleClose();
    };
    




    const handleEditChange = (event) => {

    };

    const handleDisplayEdit = () => {
      handleClose();
      DashboardDisplay.forEach(element => {
        if(element.id == SelecedId)
        {
          setSelecedDetails(element);
          setShowEditDialog(true);
          return 
        }
      });
    };


    const handleSave = () => {

        getFirestore().collection("Users").doc(user.uid).collection("Dashboard").doc(SelecedId).update(SelecedDetails)
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch((error) => {
          console.error("Error writing document: ", error);
      });

      handleClose();
    };

    
    const handleDisplayMove = () => {
      //const batch = db.batch();
     // const sfRef = db.collection('cities').doc('SF');
      //batch.update(sfRef, {population: 1000000});

      //await batch.commit();

      handleClose();
    };





    const Welcome = Widgets.Welcome.View
    const WidgetsKeys = Object.keys(Widgets).sort();


    return (
        <main className={classes.root}>
            <Box className={classes.DisplayContent}>

            <Grid container spacing={3}>




            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={showAddDialog}
              >

                  <DialogTitle sx={{ m: 0, p: 2 }}>
                    Add Widget
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      {<CloseIcon />}
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>

                  <Grid container spacing={2}>
                    {WidgetsKeys && WidgetsKeys.map((item) =>{

                      const Widget = Widgets[item]

                      if (!Widget.Settings || !Widget.Settings.Name || !Widget.Settings.Display) 
                        return null

                      return(<Grid item xs={12} sm={6} md={4} lg={4}>
                          <Button fullWidth onClick={() => handleDisplayAdd(item)}>{ Widget.Settings.Name}</Button>
                      </Grid>)
                    })}
                  </Grid>
                  <p>There are more coming, <Link to="/contact">send us a message</Link> if there is a widget that you think would be good. </p>

                </DialogContent>
                {/*}
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                  </DialogActions>*/}
            </BootstrapDialog>


            

            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={showEditDialog}>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    Edit
                    {SelecedDetails &&  SelecedDetails.widget && Widgets[SelecedDetails.widget] && Widgets[SelecedDetails.widget].Settings && Widgets[SelecedDetails.widget].Settings.document &&  Widgets[SelecedDetails.widget].Settings.document.url?
                    
                    <IconButton
                      aria-label={Widgets[SelecedDetails.widget].Settings.document.label ? Widgets[SelecedDetails.widget].Settings.document.label : null}
                      to={ "/documents"+Widgets[SelecedDetails.widget].Settings.document.url}
                      component={NavLink}
                      target="_blank"
                      style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <HelpIcon />
                    </IconButton>:null


                    }
                </DialogTitle>

                <DialogContent dividers>
                  <WidgetsSettings handleDetailsChange={handleDetailsChange} SelecedDetails={SelecedDetails}  handleEditChange={handleEditChange}/>
                

                </DialogContent>
                
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                  <Button autoFocus onClick={handleSave}>
                    Save
                  </Button>
                  </DialogActions>
            </BootstrapDialog>




            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleDisplayEdit}>Edit</MenuItem>
              {/*<MenuItem onClick={handleClose}>Move</MenuItem>*/}
              <MenuItem onClick={handleDisplayRemove}>Delete</MenuItem>
            </Menu>




                {!isEmpty(DashboardDisplay)? null : <Grid item xs={12}>
                  
                  <Card className={classes.CardRoot}>
                    <CardHeader /*
                      action={
                        <IconButton aria-label="settings"  onClick={(event) => handleMenuClick(event,null)}>
                          <MoreVertIcon />

                        </IconButton>
                      }*/
                      title="Welcome"
                    />
                    <CardContent>
                      <Welcome/>
                    </CardContent>
                  </Card>
                </Grid> }




                { DashboardDisplay && DashboardDisplay.map((item)=>{
                  
                  const Widget = Widgets[item.widget];
                  if(!Widget)
                    return null
                    
                  const WidgetView = Widget.View;

                  return (
                <Grid item  xs={item.xs?item.xs:12} sm={item.sm?item.sm:12} md={item.md?item.md:12} lg={item.lg?item.lg:12}>
                  
                  <Card variant="outlined" className={classes.CardRoot}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings" onClick={(event) => handleMenuClick(event,item.id)}>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={item.name ? item.name : Widget.Settings.Name}
                    />
                    <CardContent>
                      <WidgetView DevicesDetails={UserDetails}  DeviceData={null} Settings={item} />
                    </CardContent>
                  </Card>
                </Grid>)

                })}

              </Grid>

            </Box>


            <Box className={classes.AddBox}>
              <Button  onClick={handleAddButton}  className={classes.AddButton}>Add</Button>
            </Box>
        </main>
        )
}





const mapStateToProps = (state) => {
    
  var UserDetails = null;
  //var DeviceData = null;
  var DashboardDisplay = null;
  
  if(state.firestore.ordered.DashboardDisplay)
      DashboardDisplay = state.firestore.ordered.DashboardDisplay;

  if(state.firestore.ordered.UserDetails)
      UserDetails = state.firestore.ordered.UserDetails[0];

  //if(state.firestore.ordered.DeviceData)
  //    DeviceData = state.firestore.ordered.DeviceData;
  
    return {
        user:firebase.auth().currentUser,
        UserDetails: UserDetails,
        //DeviceData: DeviceData,
        DashboardDisplay: DashboardDisplay,
    }
}



export default compose(
    connect(mapStateToProps),
    
    firestoreConnect(props =>{
        const user = firebase.auth().currentUser
        return[
            { collection: 'Users', doc:user.uid, storeAs: 'DashboardDisplay',subcollections: [
              { collection: 'Dashboard',orderBy:["widgetOrder","asc"]},
            ]},
            { collection: 'Users', doc:user.uid, storeAs: 'UserDetails'},
        ];
  })
)(View);