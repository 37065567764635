import React,  {useState } from 'react'

import {useParams } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { isLoaded,isEmpty  } from  'react-redux-firebase'
//import DeviceList from './devices/List'
import { connect } from 'react-redux'
import { compose } from 'redux'
//import { firestore } from 'firebase'
import {  getFirestore } from 'redux-firestore'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {Box,Button,TextField,Alert,Switch,Chip,MenuItem,IconButton} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Loader from "../../layout/Loader"
import Page404 from "../Page404"

import { makeStyles } from '@mui/styles';
import mainStyle from "../../assets/mainStyle"
const useStyles = makeStyles((theme) => ({
  ...mainStyle,
  root: {
    //margin: '0 ',
    padding: '30px',
    width:"100%"
  },
  title:{
    color:"#444",
    textDecoration:"none",
  },
  inputItem:{
    display:"flex",
    marginBottom: "15px !important;",
    ['@media (max-width:900px)']: { 
      display:"block",
      marginBottom: "30px !important;",
    }
  },
  inputItemFieldName:{
    flexGrow:"1",
    marginTop:" 16px !important",
    marginBottom: "8px !important;",
    marginRight: "8px !important",
    ['@media (max-width:900px)']: { 
      width:"100%",
      marginRight: "",
    }
  },
  inputItemFieldUnit:{
    flexGrow:"1",
    marginTop:" 16px !important",
    marginBottom: "8px !important;",
    width:"300px",
    ['@media (max-width:900px)']: { 
      width:"100%",
    }
  },
  InputRemoveButton:{
    width:"50px",
    height:"50px"
  },
  inputGroup:{
    display:"flex",
    flexGrow:"1",
    ['@media (max-width:900px)']: { 
      width:"100%",
    }
  }
}));

const ActivityDevice = ({Details,Results,Units,user,Loaded,Empty}) =>{    
        
    const classes = useStyles();

    let { id } = useParams();
    const [values, setValues] = useState({});
    const [inputs, setInputs] = useState(null);
    const [{loading, error,saved}, setStatus] = useState({});

    useFirestoreConnect([
        { collection: 'Devices', storeAs: 'ActivityDetails', doc:id},

        { collection: 'Units', storeAs: 'Units',orderBy:"name"},
    ])


    const handleChangeInput = (index,event) => {
      setStatus({})
      var inputsTemp = inputs?[...inputs] : Details.inputs ? Object.values(Details.inputs) : []
      inputsTemp[index][event.target.name] = event.target.value
      setInputs(inputsTemp);
    };
  
    const handleAddInput = () => {
      setStatus({})
      if(inputs)
      {
        setInputs([...inputs,{}]);
      }
      else if(Details && Details.inputs)
      {
        setInputs([...Object.values(Details.inputs),{}]);
      }
      else
      {
        setInputs([{}]);
      }
    };
  
    const handleRemoveInput = (index) => {
      setStatus({})
  
      var inputsTemp = inputs?[...inputs] : Details.inputs ? Details.inputs : []
      inputsTemp.splice(index, 1);
      setInputs(inputsTemp);
    };
  
    






    
  const handlePlaced = (event, newPlaced) => {
    setValues({
      ...values,
      placed: newPlaced
    });
  };


        
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
  
    const handleCheckChange = (event) => {
      setStatus({})
      setValues({
        ...values,
        [event.target.name]: event.target.checked
      });
    };
  
    
    const handleSubmit = (event) => { 
        event.preventDefault();

        setStatus({loading:true})



        
        
        var valuesTemp = {...values}
        if(inputs)
        {
          valuesTemp.inputs = {}
          inputs.forEach((element) => {
            if(element.id)
              valuesTemp.inputs[element.id]   = element
            else if(element.name)
              valuesTemp.inputs[element.name] = element
          });
        }

        getFirestore().collection("Devices").doc(id).update(valuesTemp).then(() => {
          setStatus({saved:true})
        }).catch((error) => {
          setStatus({error:true})
          console.error("Error removing document: ", error);
        });
    }

    if(!Loaded)
    {
        return (<Loader/>)
    }

    if(Empty || (!Details.admin && !Details.admin.includes(user.uid)))
    {
        return (<Page404/>)
    }


        return (
            <main className={classes.root}>
                    <Box>
                        <h1>Edit Details - {Details.name}</h1>

                        <Box sx={{height:"50px"}}>
                            {error ? <Alert severity="error">Error — There was an error.</Alert> :null}
                            {loading ? <Alert severity="info">Loading  —  please wait.</Alert> :null}
                            {saved ? <Alert severity="success">Saved</Alert> :null}
                        </Box>


                        {/*Details.type ? <Box>Device: <NavLink to={"/device/"+Details.type}>{Details.type}</NavLink></Box> : null*/}
                        
                        <TextField margin="normal" fullWidth value={"name" in values ? values.name : Details.name ? Details.name : null} onChange={handleChange} name="name" className={classes.TextField} variant="outlined" label="Name" />
                        <TextField margin="normal" fullWidth value={"location" in values ? values.location : Details.location ? Details.location : null}  onChange={handleChange} name="location" className={classes.TextField} variant="outlined" label="Location" />
                        
                        
<Box>
  <p>Select where the device is placed.</p>
<ToggleButtonGroup
      value={values.placed ? values.placed : Details.placed ? Details.placed : null}
      exclusive
      onChange={handlePlaced}
      aria-label="Select where the device is placed"
    >
      <ToggleButton value="inside" aria-label="inside">
        Inside
      </ToggleButton>
      <ToggleButton value="outsideUndercover" aria-label="outside">
        Outside and undercover
      </ToggleButton>
      <ToggleButton value="outside" aria-label="outside">
        Outside
      </ToggleButton>
    </ToggleButtonGroup>
</Box>

                        
                        
                        <TextField margin="normal" fullWidth value={"description" in values ? values.description : Details.description ? Details.description : null} onChange={handleChange} name="description" className={classes.TextField} variant="outlined" label="Description" multiline rows={4}/>
                        

                        
                        <Box sx={{marginBottom:"50px"}}>
                          <h3>Inputs</h3>
                          <Box>
                            {(inputs ? inputs : Details.inputs ? Object.values(Details.inputs) : []).map((item,index)=>{
                              return(<Box className={classes.inputItem}>
                                <TextField margin="normal" fullWidth value={item.name ? item.name : null} onChange={(event)=>handleChangeInput(index,event)} name="name"  className={classes.inputItemFieldName} variant="outlined" label="Input Name" />
                              <Box className={classes.inputGroup}>
                                <TextField  select label="Unit"  className={classes.inputItemFieldUnit} name="unit"  defaultValue="Unknown" value={item.unit ? item.unit : "Unknown"} onChange={(event)=>handleChangeInput(index,event)}  >
                                  {Units && Units.map((option) => {
                                    if( !option.id || !option.name)
                                      return null

                                    if(option.id == "Unknown" )
                                      return(<MenuItem  disabled value={option.id}>
                                        {option.name} {option.unitSymbol ? "("+option.unitSymbol+")" :null}
                                      </MenuItem>)

                                      return(<MenuItem value={option.id}>
                                        {option.name} {option.unitSymbol ? "("+option.unitSymbol+")" :null}
                                      </MenuItem>)
                                  })}
                                </TextField>

                                  <Box sx={{padding:"20px"}}>
                                    <IconButton className={classes.InputRemoveButton} color="primary" aria-label="Remove input" onClick={()=>handleRemoveInput(index)} >
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                  </Box>
                                
                              </Box>)
                            })}
                            <Button  variant="contained" onClick={handleAddInput}>Add inputs</Button>
                          </Box>
                        </Box>





                      <Box sx={{display:"flex"}}>
                        <Box sx={{margin:" 8px 20px 8px 0",}}>Private</Box>
                        <Switch
                          checked={"private" in values ? values.private : Details && "private" in Details  ? Details.private : false  }
                          name="private"
                          onChange={handleCheckChange}
                        />
                      </Box>



                        {/*

                        {Details.inputs ? <Box>
                                <h2>Inputs</h2>
                                <ul>
                                    { inputs && inputs.map(input => {
                                        return (<li>{input}</li>)
                                    })}
                                </ul>  
                                </Box> : null*/}

                            <br/>

                            <Button margin="normal" onClick={handleSubmit}  type="submit" className={classes.SendButton} variant="contained">Save</Button>

                    </Box>
            </main>
        )
}



const mapStateToProps = (state) => {

    var ActivityDetails = null;
    if(state.firestore.ordered.ActivityDetails)
    ActivityDetails = state.firestore.ordered.ActivityDetails[0];

    return {
        user:firebase.auth().currentUser,
        Details:ActivityDetails,
        Units:state.firestore.ordered.Units,
        Loaded:isLoaded(state.firestore.ordered.ActivityDetails,state.firestore.ordered.Units),
        Empty:isEmpty(state.firestore.ordered.ActivityDetails,state.firestore.ordered.Units) 
    }
}

export default compose(
    connect(mapStateToProps),
    
)(ActivityDevice);